import { Button } from "antd";
import {
  useGetProfileQuery,
  useLogoutMutation,
} from "../../api/reg-service.api";
import { ROUTE_PATH } from "../../constants";
import { IBaseComponentProps } from "../../types";
import "./MobileProfileWidget.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import formatMessage from "../../utils/formatMessage";
import { injectIntl } from "react-intl";

interface IProps extends IBaseComponentProps {
  onClose: VoidFunction;
}

const LANDING_HEADER_LOG_IN = { id: "landing-header.log-in" };
const LANDING_HEADER_DASHBOARD = { id: "landing-header.dashboard" };
const LANDING_HEADER_LOGOUT = { id: "landing-header.logout" };
const LANDING_HEADER_SIGNUP = { id: "landing-header.sign-up" };

const MobileProfileWidget: React.FC<IProps> = ({
  className,
  intl,
  onClose,
}) => {
  const { isSuccess } = useGetProfileQuery();
  const [logout] = useLogoutMutation();
  return (
    <div className={cn("mobile-profile-widget", className)}>
      {isSuccess ? (
        <>
          <Link onClick={onClose} to={ROUTE_PATH.ROOT}>
            <Button
              className="landing-header-modal_button landing-header-modal_button_empty"
              type="primary"
            >
              {formatMessage(intl, LANDING_HEADER_DASHBOARD)}
            </Button>
          </Link>
          <Button
            className="landing-header-modal_button landing-header-modal_button_free"
            type="primary"
            onClick={() => {
              onClose();
              logout();
            }}
          >
            {formatMessage(intl, LANDING_HEADER_LOGOUT)}
          </Button>
        </>
      ) : (
        <>
          <Link onClick={onClose} to={ROUTE_PATH.LOGIN}>
            <Button
              className="landing-header-modal_button landing-header-modal_button_free"
              type="primary"
            >
              {formatMessage(intl, LANDING_HEADER_LOG_IN)}
            </Button>
          </Link>
          <Link onClick={onClose} to={ROUTE_PATH.REGISTER}>
            <Button
              className="landing-header-modal_button landing-header-modal_button_empty"
              type="primary"
            >
              {formatMessage(intl, LANDING_HEADER_SIGNUP)}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default injectIntl(MobileProfileWidget);
