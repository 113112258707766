/* eslint-disable no-useless-escape */
import { FC } from "react";
import { injectIntl } from "react-intl";
import "./CodeExamples.sass";
import formatMessage from "../../utils/formatMessage";
import useToken from "antd/es/theme/useToken";
import { Tooltip } from "antd";
import copyingToClipboard from "../../utils/copyingToClipboard";
import { ReactComponent as CopyOutlined } from "../../resources/images/copy_outlined.svg";
import { IBaseComponentProps } from "../../types";
import { createRpcUrl } from "../../constants";

type TProps = {
  apiKey?: string;
} & IBaseComponentProps;

const RPC_PANEL_CODE_EXAMPLES = { id: "rpc-panel.code-examples" };
const RPC_PANEL_CODE_EXAMPLES_TITLE = { id: "rpc-panel.code-examples-title" };

const CodeExamples: FC<TProps> = ({
  intl,
  className,
  apiKey = "<your-api-key>",
}) => {
  const [, token] = useToken();

  return (
    <div
      style={{
        backgroundColor: token.colorPrimaryBg,
      }}
      className={`code-examples ${className}`}
    >
      <h2>{formatMessage(intl, RPC_PANEL_CODE_EXAMPLES)}</h2>
      <div className="code-examples__item">
        <span>
          {formatMessage(intl, RPC_PANEL_CODE_EXAMPLES_TITLE)}
          <Tooltip trigger={["click", "focus"]} title="copied">
            <div
              onClick={() =>
                copyingToClipboard(
                  `curl ${createRpcUrl(
                    apiKey
                  )} \-X POST \-H "Content-Type: application/json" \-d '{"id": 1, "jsonrpc": "2.0", "method": "getBlockProduction"}'`
                )
              }
              className="code-examples__button_wrapper"
            >
              <CopyOutlined className="code-examples__item_button" />
            </div>
          </Tooltip>
        </span>
        <div>
          <span>curl</span> {`${createRpcUrl(apiKey)}`} \ <br />
          -X POST \ <br />
          -H “Content-Type: application/json” \ <br />
          {`-d '{“id”: 1, “jsonrpc”: “2.0”, "method”: “getBlockProduction”}'`}
        </div>
      </div>
      {/* <div className="code-examples__item">
        <span>
          {formatMessage(intl, RPC_PANEL_CODE_EXAMPLES_TITLE)}
          <Tooltip trigger={["click", "focus"]} title="copied">
            <div
              onClick={() => copyingToClipboard(`
            curl ${createRpcUrl(apiKey)} \
            -X POST \
            -H "Content-Type: application/json" \
            -d '{"id": 1, "jsonrpc": "2.0", "method": "getBlockProduction"}'          
            `)}
              className="code-examples__button_wrapper"
            >
              <CopyOutlined className="code-examples__item_button" />
            </div>
          </Tooltip>
        </span>
        <div>
          <span>curl</span>{" "}
          {`${createRpcUrl(apiKey)}`}{" "}
          \ <br />
          -X POST \ <br />
          -H “Content-Type: application/json” \ <br />
          {`-d '{“id”: 1, “jsonrpc”: “2.0”, method”: “getBlockProduction”}'`}
        </div>
      </div> */}
    </div>
  );
};
export default injectIntl(CodeExamples);
