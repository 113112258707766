import cn from "classnames";

import "./LoginForm.sass";
import Icon from "@ant-design/icons";
import { Button, Form, Input, Space, Typography } from "antd";
import { injectIntl } from "react-intl";
import formatMessage from "../../utils/formatMessage";
import { ROUTE_PATH } from "../../constants";
import { Link } from "react-router-dom";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import { useLoginMutation } from "../../api/reg-service.api";
import { useState } from "react";
import InputPassword from "../InputPassword/InputPassword";
import { responsePathOr } from "../../utils/utils";
import { IBaseComponentProps } from "../../types";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";

interface IProps extends IBaseComponentProps {}

interface IFormInput {
  email: string;
  password: string;
}

const TITLE = { id: "login-form.title" };
const SUBTITLE = { id: "login-form.subtitle" };
const EMAIL_LABEL = { id: "login-form.email-label" };
const PASSWORD_LABEL = { id: "login-form.password-label" };
const SIGNIN = { id: "login-form.signin" };
const NO_ACCOUNT = { id: "login-form.no-account" };
const SIGNUP = { id: "login-form.signup" };

const openCloseIcons = (visible: boolean) =>
  visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

const LoginForm: React.FC<IProps> = ({ className, intl }) => {
  const [login, { isLoading, isSuccess }] = useLoginMutation();
  const [form] = Form.useForm<IFormInput>();
  const [submitError, setSubmitError] = useState<string>();
  const { landingId } = useGetLandingLinkByAuthorizedUser();
  const onSubmit = async (values: IFormInput) => {
    try {
      window.gtag && window.gtag("event", "login");
      await login({
        ...values,
        email: values.email.toLowerCase(),
        landingId: landingId,
      }).unwrap();
    } catch (err) {
      setSubmitError(responsePathOr(err));
    }
  };

  const disabled = isLoading || isSuccess;

  return (
    <Form
      form={form}
      className={cn("login-form", className)}
      onFinish={onSubmit}
      onFieldsChange={() => {
        if (submitError) setSubmitError("");
      }}
      layout="vertical"
    >
      <Form.Item>
        <Typography.Title level={5}>
          {formatMessage(intl, TITLE)}
        </Typography.Title>
        <Typography className="login-form_subtitle">
          {formatMessage(intl, SUBTITLE)}
        </Typography>
      </Form.Item>

      <Form.Item
        label={formatMessage(intl, EMAIL_LABEL)}
        name={"email"}
        rules={[
          {
            // required: true,
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={formatMessage(intl, PASSWORD_LABEL)}
        rules={[
          {
            min: 8,
          },
        ]}
        name={"password"}
      >
        <InputPassword iconRender={openCloseIcons} />
      </Form.Item>
      <Form.Item className="login-form_button">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form_submit"
          disabled={disabled}
        >
          {formatMessage(intl, SIGNIN)}
        </Button>
      </Form.Item>
      <Form.Item style={{ marginBottom: "0" }}>
        <Space>
          <Typography
            style={{
              fontSize: 14,
            }}
          >
            {formatMessage(intl, NO_ACCOUNT)}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
            }}
          >
            <Link to={`${ROUTE_PATH.REGISTER}?landing-id=${landingId}`}>
              {formatMessage(intl, SIGNUP)}
            </Link>
          </Typography>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default injectIntl(LoginForm);
