import { FC } from "react";
import { injectIntl } from "react-intl";
import "./LandingFooter.sass";
import logo from "../../resources/images/logo_footer_landing.svg";
import linkedInIcon from "../../resources/images/linkedin.svg";
// import discordIcon from "../../resources/images/discord.svg";
import telegramIcon from "../../resources/images/telegram.svg";
import xIcon from "../../resources//images/twitterx.svg";
import formatMessage from "../../utils/formatMessage";
import { Link, useLocation } from "react-router-dom";
import { EXTERNAL_LINKS, ROUTE_PATH } from "../../constants";
import { getYear } from "../../utils/utils";
// import useScreenWidth from "../../hooks/useScreenWidth";
import ScrollToTopByClick from "../../hocs/scrollToTopByClick";
import { IBaseComponentProps } from "../../types";
import WindowWithEmailAddress from "../../hocs/windowWithEmailAddress/windowWithEmailAddress";

type TProps = {} & IBaseComponentProps;

const LANDING_FOOTER_COMPANY = { id: "landing-footer.company" };
const LANDING_FOOTER_ABOUT_US = { id: "landing-footer.about-us" };
const LANDING_FOOTER_CONTACT_US = { id: "landing-footer.contact-us" };
const LANDING_FOOTER_SUPPORT = { id: "landing-footer.support" };

// const LANDING_FOOTER_FAQ = { id: "landing-footer.faq" };
export const LANDING_FOOTER_TERMS_OF_SERVICE = {
  id: "landing-footer.terms-of-service",
};
export const LANDING_FOOTER_PRIVACY_POLICE = {
  id: "landing-footer.privacy-police",
};
const LANDING_FOOTER_PLATFORM = { id: "landing-footer.platform" };
const LANDING_FOOTER_OUR_SOLUTUINS = { id: "landing-footer.our-solutuins" };
const LANDING_FOOTER_PRICE = { id: "landing-footer.price" };
const LANDING_FOOTER_DOCUMENTATION = { id: "landing-footer.documentation" };

const LandingFooter: FC<TProps> = ({ intl }) => {
  // const isSmallScreen = useScreenWidth();
  const { pathname } = useLocation();

  const isDocumentationPath = pathname.includes("documentation");
  const contactUs = () => (
    <span>
      <WindowWithEmailAddress className="landing-footer__popup">
        {/* <a href={`mailto:${CONTACT_EMAIl}`}> */}
        {formatMessage(intl, LANDING_FOOTER_CONTACT_US)}
        {/* </a> */}
      </WindowWithEmailAddress>
    </span>
  );

  return (
    <div
      className={`landing-footer 
        ${isDocumentationPath && "landing-footer__documentation_background"}
      `}
    >
      <div className="landing-footer_left_block">
        {/* <Link style={{ all: "unset" }} to={ROUTE_PATH.LANDING}> */}
        <button className="logo-block" title="landing">
          <img className="landing-footer_logo" src={logo} alt="" />
        </button>
        {/* </Link> */}
        <div className="landing-footer_left_block_bot">
          <p>© {getYear()} Best Architects L.L.C-FZ</p>
          <div className="landing-footer_icons_block">
            <a href={EXTERNAL_LINKS.LINKED} target="_blank" rel="noreferrer">
              <img src={linkedInIcon} alt="" />
            </a>
            {/* <img src={discordIcon} alt="" /> */}
            <a href={EXTERNAL_LINKS.TELEGRAM} target="_blank" rel="noreferrer">
              <img src={telegramIcon} alt="" />
            </a>
            <a href={EXTERNAL_LINKS.TWITTER} target="_blank" rel="noreferrer">
              <img src={xIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="landing-footer_rigth_block">
        <div className="landing-footer_rigth_block_item">
          <span>{formatMessage(intl, LANDING_FOOTER_COMPANY)}</span>
          <span>
            <Link target="_blank" to={EXTERNAL_LINKS.BESTARCH}>
              {formatMessage(intl, LANDING_FOOTER_ABOUT_US)}
            </Link>
          </span>
          {/* {contactUs()} */}
        </div>
        <div className="landing-footer_rigth_block_item">
          <span>{formatMessage(intl, LANDING_FOOTER_SUPPORT)}</span>
          {contactUs()}

          <span>
            <Link
              to={ROUTE_PATH.LANDING_TERMS_OF_SERVICE}
              target={`${isDocumentationPath ? "" : "_blank"}`}
              rel="noreferrer"
            >
              {formatMessage(intl, LANDING_FOOTER_TERMS_OF_SERVICE)}
            </Link>
          </span>
          <span>
            <Link
              to={ROUTE_PATH.LANDING_PRIVACY_POLICY}
              target={`${isDocumentationPath ? "" : "_blank"}`}
              rel="noreferrer"
            >
              {formatMessage(intl, LANDING_FOOTER_PRIVACY_POLICE)}
            </Link>
          </span>
          {/* <span>{formatMessage(intl, LANDING_FOOTER_FAQ)}</span> */}
        </div>
        <div className="landing-footer_rigth_block_item">
          <span>{formatMessage(intl, LANDING_FOOTER_PLATFORM)}</span>
          <span>
            <ScrollToTopByClick>
              <Link to={ROUTE_PATH.LANDING_OUR_SOLUTION}>
                {formatMessage(intl, LANDING_FOOTER_OUR_SOLUTUINS)}
              </Link>
            </ScrollToTopByClick>
          </span>
          <span>
            <ScrollToTopByClick>
              <Link
                target={`${isDocumentationPath ? "" : "_blank"}`}
                to={ROUTE_PATH.LANDING_PRICING}
              >
                {formatMessage(intl, LANDING_FOOTER_PRICE)}
              </Link>
            </ScrollToTopByClick>
          </span>
          <span>
            <ScrollToTopByClick>
              <Link
                target={`${isDocumentationPath ? "" : "_blank"}`}
                to={ROUTE_PATH.LANDING_SERVICE_INTRODUCTION}
              >
                {formatMessage(intl, LANDING_FOOTER_DOCUMENTATION)}
              </Link>
            </ScrollToTopByClick>
          </span>
        </div>
      </div>
    </div>
  );
};
export default injectIntl(LandingFooter);
