import { FC } from "react"
import "./HeaderLine.sass"

type TProps = {
  className?: string
}
const HeaderLine: FC<TProps> = ({ className }) => {
  return (
    <div className={`header-line ${className}`}></div>
  )
}
export default HeaderLine;