import { useGetMeQuery } from "../api/reg-service.api";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { ROUTE_PATH, isSnapshot } from "../constants";

type TComponentProps = any;
interface IHocProps {
  disableAuthRedirect?: boolean;
}

const witAutorizedRedirect =
  (Component: React.FC) => (props: TComponentProps & IHocProps) => {
    const { data: user, error: userError, isLoading } = useGetMeQuery();

    const navigate = useNavigate();

    const redirectCondition = useMemo(() => {
      return !!(user && !userError && !isSnapshot);
    }, [user, userError]);

    useEffect(() => {
      if (redirectCondition) {
        navigate(ROUTE_PATH.ROOT);
      }
    }, [navigate, redirectCondition]);

    if (!isSnapshot && (isLoading || redirectCondition)) {
      return <div className="loader"></div>;
    }
    return <Component {...props} />;
  };

export default witAutorizedRedirect;
