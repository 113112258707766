import { useLocation } from "react-router-dom";
import { getLandingId, getLandingPageLinkById } from "../utils/utils";
import { useGetProfileQuery } from "../api/reg-service.api";
import { DEFAULT_LANDING_ID } from "../constants";

const useGetLandingLinkByAuthorizedUser = (): {
  landingId: number;
  landingLink: string;
} => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramsLandingId = searchParams.get("landing-id") || "";
  const storageLandingId = getLandingId() || "";
  const { data: profile } = useGetProfileQuery();
  const profileLandingId = profile?.landingId;
  if (profileLandingId)
    return {
      landingId: +profileLandingId,
      landingLink: getLandingPageLinkById(profileLandingId),
    };
  if (paramsLandingId)
    return {
      landingId: +paramsLandingId,
      landingLink: getLandingPageLinkById(paramsLandingId),
    };
  if (!storageLandingId && !profileLandingId && !paramsLandingId)
    return {
      landingId: +DEFAULT_LANDING_ID,
      landingLink: getLandingPageLinkById(DEFAULT_LANDING_ID),
    };
  else
    return {
      landingId: +storageLandingId,
      landingLink: getLandingPageLinkById(storageLandingId),
    };
};

export default useGetLandingLinkByAuthorizedUser;
