/* eslint-disable react-hooks/exhaustive-deps */
import { injectIntl } from "react-intl";
import cn from "classnames";
import formatMessage from "../../utils/formatMessage";
import useToken from "antd/es/theme/useToken";
import { TPlan } from "../../entities/plan";

import "./SubscriptionDetails.sass";
import { IBaseComponentProps } from "../../types";
import DependsOnLengthSize from "../DependsOnLengthSize/DependsOnLengthSize";
import { convertBigNumberToNumber } from "../../utils/convertBigNumberToNumber";
import { useGetCurrentPlanQuery, useGetPaymentTokensQuery } from "../../api/reg-service.api";
import usePlanType, { EPlanType } from "../../hooks/usePlanType";
import { Button } from "antd";
import { TSubscription } from "../../entities/subscription";
import { useMemo } from "react";

const statusMap: Record<TSubscription["status"], string> = {
  active: "Active",
  canceled: "Canceled",
  past_due: "Overdue",
  paused: "Paused",
  trialing: "Trialing",
};

interface IProps extends IBaseComponentProps {
  plan: TPlan;
  canEdit: boolean;
  subscription: TSubscription & { endDate?: number };
  editPlanAction: () => void;
}
const HOME_PAGE_CURRENT_PLAN = { id: "home-page.current-plan" };
// const HOME_PAGE_MONTHLY_QUOTA = { id: "home-page.monthly-quota" };
// const HOME_PAGE_RENEWS_ON = { id: "home-page.renews-on" };
const HOME_PAGE_FREE = { id: "home-page.free" };
const HOME_PAGE_PRO = { id: "home-page.pro" };
export const HOME_PAGE_TAILORED = { id: "home-page.tailored" };
// const HOME_PAGE_MONTHLY_CHARGES = { id: "home-page.monthly-charges" };
const HOME_PAGE_STATUS = { id: "home-page.status" };
const HOME_PAGE_PRICE = { id: "home-page.price" };
const HOME_PAGE_RPS = { id: "home-page.rps" };
const HOME_PAGE_START_DATE = { id: "home-page.start-date" };
const HOME_PAGE_BILLING_PERIOD = { id: "home-page.billing-period" };
const HOME_PAGE_RENEWAL_DATE = { id: "home-page.renewal-date" };
const HOME_PAGE_END_DATE = { id: "home-page.end-date" };
const PLUG = { id: "plug" }

const mapPlanTypeToName: Record<EPlanType, { id: string }> = {
  [EPlanType.DEFAULT]: HOME_PAGE_FREE,
  [EPlanType.STANDARD]: HOME_PAGE_PRO,
  [EPlanType.TAILORED]: HOME_PAGE_TAILORED,
  [EPlanType.UNKNOWN]: HOME_PAGE_FREE,
  [EPlanType.NEW_STANDARD]: PLUG
};

const SubcriptionDetails: React.FC<IProps> = ({
  intl,
  className,
  plan,
  subscription,
  editPlanAction,
  canEdit,
}) => {
  const [, token] = useToken();
  const { data: tokens } = useGetPaymentTokensQuery();
  const paymentToken = tokens ? tokens[0] : undefined;
  const planType = usePlanType(plan);
  const { data: currentPlanData } = useGetCurrentPlanQuery()

  // const renewsOn = useMemo(() => {
  //   if (additionToPlan.isDefault) return "∞";
  //   if (plan) return new Date(plan?.validUntil || "").toLocaleDateString();
  //   else return "-";
  // }, [additionToPlan, plan]);

  const planName = useMemo(() => {
    if (planType === EPlanType.NEW_STANDARD) {
      return currentPlanData?.name
    }
    if (subscription && subscription.status === "past_due") {
      return "-"
    }

    return formatMessage(intl, mapPlanTypeToName[planType])
  }, [currentPlanData])

  return (
    <div className="subscription-details__wrapper">
      <div
        style={{ backgroundColor: token.colorPrimaryBg }}
        className={cn(
          "subscription-details",
          className,
          plan.isDefault && "subscription-details__fewer_items"
        )}
      >
        <div className="subscription-details__item">
          <span className="subscription-details__item_label">
            {formatMessage(intl, HOME_PAGE_CURRENT_PLAN)}
          </span>
          <p className="subscription-details__item_value home_weight">
            {planName}
          </p>
        </div>
        <div className="subscription-details__item">
          <div className="subscription-details__item_center">
            <span className="subscription-details__item_label">
              {formatMessage(intl, HOME_PAGE_STATUS)}
            </span>
            <div className="subscription-details__item_value">
              <div
                className={cn("subscription-details__highlighting", {
                  _success: subscription.status === "active",
                  _warn:
                    subscription.status === "canceled" ||
                    subscription.status === "paused",
                  _error: subscription.status === "past_due",
                })}
              >
                {statusMap[subscription.status]}
              </div>
            </div>
          </div>
        </div>
        <div className="subscription-details__item">
          <span className="subscription-details__item_label">
            {formatMessage(intl, HOME_PAGE_PRICE)}
          </span>
          {
            plan?.price !== undefined &&
              planType !== EPlanType.TAILORED && // with TAILORED price is not known
              subscription.status !== "past_due" // with past_due price is not known
              ? (
                <p className="subscription-details__item_value _green _bold">
                  <DependsOnLengthSize
                    defaultSize={18}
                    numberOfCharactersIsNotAllowed={8}
                    ratio={0.8}
                  >
                    {`${convertBigNumberToNumber(
                      plan.price,
                      paymentToken?.decimals
                    )}$`}
                  </DependsOnLengthSize>
                </p>
              ) : (
                <p
                  style={{ paddingLeft: "15px" }}
                  className="subscription-details__item_value _green _bold"
                >
                  -
                </p>
              )}
        </div>
        {(
          (!plan.isDefault && subscription.billingCycle) || (plan.isDefault && subscription.status === "past_due"))
          && (
            <div className="subscription-details__item">
              <span className="subscription-details__item_label">
                {formatMessage(intl, HOME_PAGE_BILLING_PERIOD)}
              </span>
              {subscription.billingCycle && <p className="subscription-details__item_value">
                {`${subscription.billingCycle.frequency} ${subscription.billingCycle.interval
                  }${subscription.billingCycle.frequency > 1 ? "s" : ""}`}
              </p>}
            </div>
          )}{" "}
        <div className="subscription-details__item">
          <span className="subscription-details__item_label">
            {formatMessage(intl, HOME_PAGE_RPS)}
          </span>
          {/*  replace false  */}
          <p
            className={`subscription-details__item_value home_weight ${subscription && subscription.status === "past_due" && "_red"
              }`}
          >
            {plan?.rps}
          </p>
        </div>{" "}
        <div className="subscription-details__item">
          <span className="subscription-details__item_label">
            {formatMessage(intl, HOME_PAGE_START_DATE)}
          </span>
          <p className="subscription-details__item_value">
            {subscription.firstBilledAt ? (
              new Date(subscription.firstBilledAt).toLocaleDateString()
            ) : (
              <p style={{ paddingLeft: "34px" }}>-</p>
            )}
          </p>
        </div>{" "}
        {subscription.endDate && (
          <div className="subscription-details__item">
            <span className="subscription-details__item_label">
              {formatMessage(intl, HOME_PAGE_END_DATE)}
            </span>
            <p className="subscription-details__item_value">
              {subscription.endDate ? (
                new Date(subscription.endDate).toLocaleDateString()
              ) : (
                <p style={{ paddingLeft: "27px" }}>-</p>
              )}
            </p>
          </div>
        )}
        {
          (!plan.isDefault ||
            (plan.isDefault && subscription.status === "past_due")
          ) &&
          subscription.status !== "canceled" &&
          subscription.nextBilledAt !== "doNotDisplay" &&
          (
            <div className="subscription-details__item">
              <span className="subscription-details__item_label">
                {formatMessage(intl, HOME_PAGE_RENEWAL_DATE)}
              </span>
              <p className="subscription-details__item_value">
                {subscription.nextBilledAt ? (
                  new Date(subscription.nextBilledAt).toLocaleDateString()
                ) : (
                  <p style={{ paddingLeft: "47px" }}>-</p>
                )}
              </p>
            </div>
          )}{" "}
        {canEdit ? (
          <div className="subscription-details__item">
            <span className="subscription-details__item_label">Action</span>
            <Button
              onClick={editPlanAction}
              type={"dashed"}
              className="subscription-details__button"
            >
              Edit
            </Button>
          </div>
        ) : null}{" "}
        {/* <div className="subscription-details__item">
        <span className="subscription-details__item_label">
          {formatMessage(intl, HOME_PAGE_RENEWS_ON)}:
        </span>
        <p className="subscription-details__item_value">{renewsOn}</p>
      </div>{" "} */}
      </div>
    </div>
  );
};

export default injectIntl(SubcriptionDetails);
