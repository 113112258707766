import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import "./PaddleWidget.sass";
import cn from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CheckoutEventNames,
  CheckoutEventsStatus,
  CheckoutEventsTimePeriod,
  CheckoutEventsTotals,
  CheckoutOpenOptions,
  CurrencyCode,
  Paddle,
  PaddleEventData,
  initializePaddle,
} from "@paddle/paddle-js";
import {
  useGetPaymentTokensQuery,
  useGetPriceItemQuery,
  useGetProfileQuery,
  useGetRequesterQuery,
} from "../../api/reg-service.api";
import PaymentSummary from "../PaymentSummary";
import { useLocation } from "react-router-dom";
import { paddleCST, paddleEnv } from "../../constants";
import { TPlan } from "../../entities/plan";
import { convertBigNumberToNumber } from "../../utils/convertBigNumberToNumber";

interface IProps extends IBaseComponentProps {
  onComplete: VoidFunction;
  onClose: VoidFunction;
  selectedPlan?: TPlan; // replaced standartPlan with selectedPlan
}

const PaddleWidget: React.FC<IProps> = ({
  className,
  onComplete,
  onClose,
  selectedPlan,
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const transactionId = searchParams.get("_ptxn");

  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState<Paddle>();
  const { data: requester } = useGetRequesterQuery((() => {})(), {
    skip: !!transactionId,
  });

  const { data: profile } = useGetProfileQuery((() => {})(), {
    skip: !!transactionId,
  });

  const { data: tokens } = useGetPaymentTokensQuery();

  const paymentToken = tokens ? tokens[0] : undefined;

  const [planPrice, setPlanPrice] = useState<number>();
  const [totals, setTotals] = useState<CheckoutEventsTotals>();
  const [currency, setCurrency] = useState<CurrencyCode>();
  const [cycle, setCycle] = useState<CheckoutEventsTimePeriod>();
  // const { standartPlan } = useAvailablePlans({});
  const [status, setStatus] = useState<CheckoutEventsStatus>();

  const { data: priceItem, isLoading: isLoadingPriceItem } =
    useGetPriceItemQuery(
      {
        planId: selectedPlan ? selectedPlan!.id : -1, // always skip of has no plan
      },
      {
        skip: !selectedPlan || !!transactionId,
      }
    );

  const updateSummary = (e: PaddleEventData) => {
    if (e.data?.status) setStatus(e.data?.status);

    if (!e.name) {
      return;
    }
    console.log("events?", e);
    if (e.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
      window.gtag &&
        window.gtag("event", "purchase", {
          // Why only checkout?
          plan_name: selectedPlan?.name,
          page: window.location.pathname,
          plan_price: convertBigNumberToNumber(
            selectedPlan?.price,
            paymentToken?.decimals
          ),
          time: new Date().toISOString(),
        });
      onComplete();
    }

    if (e.data && e.data.items[0]) setCycle(e.data.items[0].billing_cycle);

    if (e.data && e.data.currency_code) setCurrency(e.data.currency_code);

    if (e.data?.totals) setPlanPrice(e.data.totals.subtotal);

    if (
      e.data &&
      e.data?.totals &&
      e.data.status === CheckoutEventsStatus.READY
    ) {
      setTotals(e.data.totals);
    }
  };

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({
      environment: paddleEnv,
      token: paddleCST,
      eventCallback: updateSummary,
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkoutParams = useMemo<CheckoutOpenOptions | undefined>(() => {
    if (transactionId) {
      return {
        transactionId,
        settings: {
          displayMode: "inline",
          theme: "dark",
          frameTarget: "checkout-container",
          frameStyle: "width: 100%; border: none;",
          locale: "en",
        },
      };
    }

    if (
      !requester ||
      !selectedPlan ||
      !profile ||
      !priceItem ||
      isLoadingPriceItem
    )
      return undefined;

    return {
      items: [{ priceId: priceItem.priceId, quantity: 1 }],
      customData: {
        authorityKey: requester.authorityKey,
        planId: selectedPlan.id,
        seqNo: priceItem?.seqNo,
      },
      customer: {
        email: profile.email,
      },
      settings: {
        displayMode: "inline",
        theme: "dark",
        frameTarget: "checkout-container",
        frameStyle: "width: 100%; border: none;",
        locale: "en",
      },
    };
  }, [
    isLoadingPriceItem,
    priceItem,
    profile,
    requester,
    selectedPlan,
    transactionId,
  ]);

  const openCheckout = useCallback(() => {
    if (!checkoutParams) return;
    paddle?.Checkout.open(checkoutParams);
  }, [checkoutParams, paddle]);

  const clearSummary = () => {
    setTotals(undefined);
    setPlanPrice(undefined);
    setCurrency(undefined);
  };

  useEffect(() => {
    if (paddle) {
      openCheckout();
    }
  }, [openCheckout, paddle]);

  const goingBack = () => {
    if (
      !status ||
      status === CheckoutEventsStatus.DRAFT ||
      status === CheckoutEventsStatus.COMPLETED
    ) {
      onClose();
    } else {
      // paddle?.Checkout.close();
      clearSummary();
      openCheckout();
    }
  };

  useEffect(() => {
    if (status === CheckoutEventsStatus.READY) {
      scrollToElement();
    }
  }, [status]);

  const fixedContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToElement = () => {
    if (fixedContainerRef.current) {
      fixedContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div ref={fixedContainerRef} className={cn("paddle-widget", className)}>
      <PaymentSummary
        className="paddle-widget__summary"
        totals={totals}
        selectedPlan={selectedPlan}
        planPrice={planPrice}
        currency={currency}
        cycle={cycle}
        onClose={goingBack}
        withoutBack={
          !!transactionId || status === CheckoutEventsStatus.COMPLETED
        }
      />
      <div className="checkout-container paddle-widget__payment" />
    </div>
  );
};

export default injectIntl(PaddleWidget);
