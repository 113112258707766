import React, { FC } from "react";
import "./RpcPanel.sass";
import useToken from "antd/es/theme/useToken";
import { Button, Input, Tooltip, Typography } from "antd";
import formatMessage from "../../utils/formatMessage";
import { injectIntl } from "react-intl";
import { ReactComponent as CopyOutlined } from "../../resources/images/copy_outlined.svg";
import deleteIcon from "../../resources/images/delete.svg";
import copyingToClipboard from "../../utils/copyingToClipboard";
import {
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetApiKeysQuery,
} from "../../api/reg-service.api";
import CodeExamples from "../CodeExamples/CodeExamples";
import { IBaseComponentProps } from "../../types";
import { createRpcUrl } from "../../constants";

type TProps = {} & IBaseComponentProps;

const RPC_PANEL_TITLE = { id: "rpc-panel.title" };
const RPC_PANEL_LABEL_ENDPOINT = { id: "rpc-panel.endpoint" };
const RPC_PANEL_LABEL_WEBSOCKET = { id: "rpc-panel.webSocket" };
const RPC_PANEL_LABEL_YOUR_API_KEY = { id: "rpc-panel.your-api-key" };
const RPC_PANEL_BUTTON = { id: "rpc-panel.button" };
// const RPC_PANEL_LABEL_YOUR_API_KEY_PLUG = { id: "rpc-panel.your-api-key-plug" };

const RpcPanel: FC<TProps> = ({ intl }) => {
  const { data: apiKeys } = useGetApiKeysQuery();

  const [createApiKey, { isLoading }] = useCreateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();

  const [, token] = useToken();

  const createKey = () => {
    return createApiKey();
  };

  const deleteKey = (key: string) => {
    return deleteApiKey({
      key,
    });
  };

  // const openCloseIcons = (visible: boolean) =>
  //   visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

  if (!apiKeys) return null;

  const rpcHttpUrl = apiKeys.length
    ? createRpcUrl(apiKeys[0])
    : createRpcUrl("<your-api-key>");

  const rpcWsUrl = apiKeys.length
    ? createRpcUrl(apiKeys[0], true)
    : createRpcUrl("<your-api-key>", true);

  return (
    <div
      style={{
        // backgroundColor: token.colorPrimaryBg,
        color: token.colorPrimary,
      }}
      className="rpcPanel"
    >
      <Typography>{formatMessage(intl, RPC_PANEL_TITLE)}</Typography>
      <span className="rpcPanel_label">
        {formatMessage(intl, RPC_PANEL_LABEL_ENDPOINT)}
      </span>
      <Input.Group className="rpcPanel_input" compact>
        <div className="rpcPanel_input_content">
          <p>{rpcHttpUrl}</p>
          <Tooltip trigger={["click"]} title="copied">
            <Button
              className="rpcPanel_tooltip_button"
              onClick={() => copyingToClipboard(rpcHttpUrl)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </div>
      </Input.Group>
      <span className="rpcPanel_label">
        {formatMessage(intl, RPC_PANEL_LABEL_WEBSOCKET)}
      </span>
      <Input.Group className="rpcPanel_input" compact>
        <div className="rpcPanel_input_content">
          <p>{rpcWsUrl}</p>
          <Tooltip trigger={["click"]} title="copied">
            <Button
              className="rpcPanel_tooltip_button"
              onClick={() => copyingToClipboard(rpcWsUrl)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </div>
      </Input.Group>
      <span className="rpcPanel_label">
        {formatMessage(intl, RPC_PANEL_LABEL_YOUR_API_KEY)}:
      </span>
      {apiKeys.length ? (
        <div
          style={{
            backgroundColor: token.colorPrimaryBg,
          }}
          className="rpcPanel_table"
        >
          <div className="rpcPanel_table_titles">
            <span>Key ID</span>
            {/* <span>Created At</span> */}
            <span>Actions</span>
          </div>
          {apiKeys.map((el: string, index) => (
            <div
              key={el}
              className={`rpcPanel_table_item ${
                index % 2 === 0 && "rpcPanel_highlight"
              }`}
            >
              <div className="rpcPanel_table_item_key_block">
                <p className="rpcPanel_table_item_key">{el}</p>
                <Tooltip trigger={["click"]} title="copied">
                  <div
                    onClick={() => copyingToClipboard(el)}
                    style={{ marginLeft: "8px" }}
                    className="rpcPanel_button_wrapper"
                  >
                    <CopyOutlined className="rpcPanel_examples_item_button" />
                  </div>
                </Tooltip>
              </div>
              <div className="rpcPanel_table_item_delete">
                <div
                  onClick={() => deleteKey(el)}
                  className="rpcPanel_button_wrapper"
                >
                  <img src={deleteIcon} alt="delete" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Button
          className="rpcPanel_button"
          onClick={createKey}
          disabled={isLoading}
        >
          {formatMessage(intl, RPC_PANEL_BUTTON)}
        </Button>
      )}
      {apiKeys.length ? <CodeExamples apiKey={apiKeys[0]} /> : null}
    </div>
  );
};
export default injectIntl(RpcPanel);
