import { ThemeConfig } from "antd";

export const dark: ThemeConfig = {
  cssVar: true,
  token: {
    colorBgContainer: "gray",
    borderRadius: 16,
    colorText: "#FFF",
    fontSize: 20,
    colorPrimaryBg: "#212529",
    colorPrimary: "white",
    colorIcon: "#ffffff",
    fontFamily: "Inter",
  },
  components: {
    Button: {
      defaultBg: "#424A51",
      defaultHoverBg: "#424A51",
      defaultHoverBorderColor: "#424A51",
      defaultHoverColor: "#FFFFFF",
      defaultBorderColor: "#424A51",
      defaultActiveBorderColor: "#424A51",
      defaultActiveBg: "#424A51",
      defaultActiveColor: "#FFFFFF",
      defaultColor: "#FFFFFF",
      colorTextDisabled: "#FFF",
      colorBgContainerDisabled: "#424A51",
      borderColorDisabled: "#424A51",
      borderRadius: 6,
      controlHeight: 40,
      fontWeight: 600,
      paddingInline: 24,
      contentFontSize: 16,
      primaryShadow: "none",
      colorPrimaryActive: "#7A8CF9",
      colorPrimaryHover: "#7A8CF9",
      colorPrimary: "#7A8CF9",
      fontFamily: "Manrope",
    },
    Input: {
      activeShadow: "none",
      colorBorder: "#212529",
      colorBgBase: "#212529",
      colorBgContainer: "#212529",
      borderRadius: 6,
      colorText: "#ffffff",
      hoverBorderColor: "#212529",
      activeBorderColor: "#212529",
      fontSize: 14,
      fontFamily: "Manrope",
      controlHeight: 40,
      colorTextPlaceholder: "#CCC",
      errorActiveShadow: "none",
      colorErrorBorder: "none",
      colorErrorBorderHover: "#212529",
      colorError: "#212529",
      paddingInline: 16,
      colorTextDisabled: "#CCC",
    },
    Form: {
      labelColor: "#ACACAC",
      fontSize: 14,
      itemMarginBottom: 16,
      verticalLabelPadding: "0px 0px 6px 0px"
    },
    Checkbox: {
      colorText: "#ffffff",
      fontSize: 14,
      colorBorder: "#7A8CF9",
      lineWidth: 2,
      controlInteractiveSize: 18,
      colorBgContainer: "#212529",
      colorPrimary: "#7A8CF9",
      colorPrimaryHover: "#7A8CF9",
      borderRadiusSM: 2,
    },
    Typography: {
      titleMarginBottom: 0,
      colorLink: "#7A8CF9",
      fontSize: 28,
    },
    Popover: {
      colorBgElevated: "#212529",
      fontSize: 14,
    },
    Modal: {
      headerBg: "#0A0C0D",
      footerBg: "#0A0C0D",
      contentBg: "#0A0C0D",
      colorText: "#FFF",
      titleColor: "#FFF",
      titleFontSize: 24,
      fontFamily: "Inter",
      fontSize: 16,
      colorBgMask: "#30363acc",
      marginXS: 20
    },
    QRCode: {
      colorSplit: "transparent",
    },
    Tooltip: {
      borderRadius: 6,
      fontSize: 16,
    },
  },
};

export const white: ThemeConfig = {
  cssVar: true,
  token: {
    colorBgContainer: "#dcd9d9",
    borderRadius: 2,
    colorText: "black",
    fontSize: 16,
    colorPrimaryBg: "#00800096",
    colorPrimary: "bkack",
  },
  components: {
    Button: {
      defaultBg: "#4c76daa3",
      defaultHoverBg: "#4c76daa3",
      defaultHoverColor: "black",
      defaultBorderColor: "gray",
      defaultHoverBorderColor: "gray",
      defaultActiveBorderColor: "gray",
      defaultActiveColor: "black",
    },
    Input: {
      activeShadow: "none",
      colorBorder: "black",
      hoverBorderColor: "black",
      activeBorderColor: "black",
    },
  },
};
