import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMeQuery } from "../api/reg-service.api";
import { ROUTE_PATH, isSnapshot } from "../constants";
import useGetLandingLinkByAuthorizedUser from "../hooks/useGetLandingLinkByAuthorizedUser";

const withUnautorizedRedirect = (Component: React.FC) => (props: any) => {
  const {
    data: user,
    error: userError,
    isSuccess,
    isError,
    isLoading,
  } = useGetMeQuery((() => { })(), {
    pollingInterval: 60000,
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();

  const redirectCondition = useMemo(
    () => !!((!user || userError) && (isSuccess || isError) && !isSnapshot),
    [isError, isSuccess, user, userError]
  );
  const { landingId } = useGetLandingLinkByAuthorizedUser()
  useEffect(() => {
    if (redirectCondition) {
      navigate(`${ROUTE_PATH.LOGIN}?landing-id=${landingId}`);
    }
  }, [landingId, navigate, redirectCondition]);

  if (!isSnapshot && (isLoading || redirectCondition))
    return <div className="loader"></div>;

  return <Component {...props} />;
};

export default withUnautorizedRedirect;
