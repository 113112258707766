import React, { FC } from "react";
import "./SideBar.sass";
import { ReactComponent as Home } from "../../resources/images/home.svg";
import { ReactComponent as Rpc } from "../../resources/images/rpc.svg";
import { ReactComponent as Documents } from "../../resources/images/documents.svg";
import { Link } from "react-router-dom";
import formatMessage from "../../utils/formatMessage";
import { injectIntl } from "react-intl";
import useToken from "antd/es/theme/useToken";
import { useLocation } from "react-router-dom";
import { DOCUMENTATION_PATH, ROUTE_PATH } from "../../constants";
import LogoBlock from "../LogoBlock/LogoBlock";
import { IBaseComponentProps } from "../../types";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";

type TProps = {
  closeModal?: () => void;
} & IBaseComponentProps;

// const SIDE_BAR_LOGO = { id: "side-bar.logo" };
// const SIDE_BAR_MAIN = { id: "side-bar.main" };
const SIDE_BAR_HOME = { id: "side-bar.home" };
const SIDE_BAR_RPC_PANEL = { id: "side-bar.rpc-panel" };
const SIDE_BAR_DOCUMENTS = { id: "side-bar.documents" };

const SideBar: FC<TProps> = ({ intl, closeModal, className }) => {
  const [, token] = useToken();
  const { pathname } = useLocation();
  const { landingLink } = useGetLandingLinkByAuthorizedUser();

  return (
    <div
      style={{
        backgroundColor: token.colorPrimaryBg,
        color: token.colorPrimary,
      }}
      className={`sideBar ${className}`}
    >
      <LogoBlock />

      <div onClick={closeModal} className="sideBar_buttonsBlock">
        <Link to={ROUTE_PATH.ROOT}>
          <div
            className={`sideBar_linkButton ${
              pathname === ROUTE_PATH.ROOT && "sideBar_linkButton_selected"
            }`}
          >
            <Home
              className={` ${
                pathname !== ROUTE_PATH.ROOT && "sideBar_linkButton_icon"
              }`}
            />
            {formatMessage(intl, SIDE_BAR_HOME)}
          </div>
        </Link>
        <Link to={ROUTE_PATH.RPC_PANEL}>
          <div
            className={`sideBar_linkButton ${
              pathname === ROUTE_PATH.RPC_PANEL && "sideBar_linkButton_selected"
            }`}
          >
            <Rpc
              className={` ${
                pathname !== ROUTE_PATH.RPC_PANEL && "sideBar_linkButton_icon"
              }`}
            />
            {formatMessage(intl, SIDE_BAR_RPC_PANEL)}
          </div>
        </Link>
        <a
          style={landingLink ? {} : { pointerEvents: "none" }}
          href={landingLink && `${landingLink}${DOCUMENTATION_PATH}`}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className={`sideBar_linkButton ${
              pathname === "/documentation" && "sideBar_linkButton_selected"
            }`}
          >
            <Documents
              className={` ${
                pathname !== "/documentation" &&
                "sideBar_linkButton_icon colorStroke"
              }`}
            />
            {formatMessage(intl, SIDE_BAR_DOCUMENTS)}
          </div>
        </a>
      </div>
    </div>
  );
};

export default injectIntl(SideBar);
