import { PropsWithChildren } from "react";
import { persistor } from "../../store";
import { PersistGate } from "redux-persist/integration/react";

const PersistGateProvider: React.FC<PropsWithChildren> = (props) => {
  return (
    <PersistGate loading={<div />} persistor={persistor}>
      {props.children}
    </PersistGate>
  );
};

export default PersistGateProvider;
