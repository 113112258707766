import { FC } from "react";
import "./InputPassword.sass";
import { Input } from "antd";
import useToken from "antd/es/theme/useToken";

type TProps = {
  autoComplete?: string;
  iconRender?: (value: boolean) => JSX.Element;
  readOnly?: boolean;
};

const InputPassword: FC<TProps> = ({ autoComplete, iconRender, readOnly, ...props }) => {
  const [, token] = useToken();
  return (
    <Input.Password
      readOnly={readOnly}
      {...props}
      style={{
        height: (token?.Input as any)?.controlHeight,
        padding: token.Input?.paddingBlock,
      }}
      className="input-password"
      autoComplete={autoComplete}
      iconRender={iconRender}
    />
  );
};

export default InputPassword;
