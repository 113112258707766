import { useMemo } from "react";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import usePlanType, { EPlanType } from "./usePlanType";

const useSubscriptionEdit = (
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) => {
  const planType = usePlanType(currentPlanQuery.data);
  return useMemo(() => {
    if (planType === EPlanType.TAILORED) {
      return {
        canEdit: false,
        disabledUpdate: true,
        disabledCancel: true,
      };
    } else if (!currentSubscriptionQuery.data) {
      return {
        canEdit: false,
        disabledUpdate: true,
        disabledCancel: true,
      };
    } else if (
      planType === EPlanType.DEFAULT &&
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "past_due"
    ) {
      return {
        canEdit: false,
        disabledCancel: true,
        disabledUpdate: true,
      };
    } else if (
      (planType === EPlanType.STANDARD ||
        planType === EPlanType.NEW_STANDARD) &&
      (currentSubscriptionQuery.data.status === "canceled" ||
        !currentSubscriptionQuery.data.nextBilledAt)
    ) {
      return {
        canEdit: false,
        disabledCancel: true,
        disabledUpdate: true,
      };
    }

    return {
      canEdit: true,
      disabledUpdate: true,
      disabledCancel: false,
    };
  }, [currentSubscriptionQuery.data, planType]);
};

export default useSubscriptionEdit;
