import React, { useState, createContext, PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";
import En from "../../languages/en.json";
import Ru from "../../languages/ru.json";

export const location = navigator.language || "en";

type TLocals = Record<string, any>;
export type TLanguage = "ru" | "en";
const Locals: TLocals = {
  ru: Ru,
  en: En,
};

const languageDefinition = () => {
  return Locals["en"];
};

export const Context = createContext({
  location,
  selectLanguage: (e: TLanguage) => {},
});

const IntlProviderWrapper: React.FC<PropsWithChildren> = (props) => {
  const [language, setLanguage] = useState("en");
  const [messages, setMessages] = useState(languageDefinition());

  function selectLanguage(value: TLanguage) {
    setLanguage(value);
    setMessages(Locals[value]);
  }

  return (
    <Context.Provider value={{ location: language, selectLanguage }}>
      <IntlProvider messages={messages} locale={language} defaultLocale="en">
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default IntlProviderWrapper;
