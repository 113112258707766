import React, { FC } from "react";
import { Modal } from "antd";
import "./LandingHeaderModal.sass";
import LogoBlock from "../LogoBlock/LogoBlock";
import formatMessage from "../../utils/formatMessage";
import { injectIntl, IntlShape } from "react-intl";
import whiteArrowIcon from "../../resources/images/white_arrow.svg";
import HeaderLine from "../HeaderLine/HeaderLine";
import { LANDING_HEADER_PRICING } from "../LandingHeader/LandingHeader";
import MobileProfileWidget from "../MobileProfileWidget";

const LANDING_HEADER_DOCUMENTATION = {
  id: "landing-header.documentation",
};
const LANDING_HEADER_OUR_SOLUTION = {
  id: "landing-header.our-solution",
};
type TProps = {
  isVisible: boolean;
  setisVisible: (value: boolean) => void;
  intl: IntlShape;
  buttonClick: (value: boolean) => void;
};

const LandingHeaderModal: FC<TProps> = ({
  isVisible,
  setisVisible,
  intl,
  buttonClick,
}) => {
  return (
    <Modal
      transitionName={""}
      width="100%"
      style={{ top: 0, bottom: 0, maxWidth: "100%", margin: 0 }}
      title={
        <div className="landing-header-modal__title_block">
          <LogoBlock className="landing-header-modal__logo" />
          <HeaderLine className="landing-header-modal__line" />
        </div>
      }
      open={isVisible}
      // onOk={handleOk}
      closeIcon={
        <button className="landing-header-modal__button_close"></button>
      }
      onCancel={() => setisVisible(false)}
      className="landing-header-modal"
      footer={
        <MobileProfileWidget
          onClose={() => setisVisible(false)}
          className="landing-header-modal__buttons"
        />
      }
    >
      <p
        style={{ left: 0, animationDelay: "0s" }}
        className="landing-header_line"
      ></p>
      {/* <Link
        onClick={() => setisVisible(false)}
        to={ROUTE_PATH.LANDING_OUR_SOLUTION}
      > */}
      {formatMessage(intl, LANDING_HEADER_OUR_SOLUTION)}
      <img src={whiteArrowIcon} alt="" />
      {/* </Link> */}
      {/* <Link onClick={() => setisVisible(false)} to={ROUTE_PATH.LANDING_PRICING}> */}
      {formatMessage(intl, LANDING_HEADER_PRICING)}
      <img src={whiteArrowIcon} alt="" />
      {/* </Link> */}
      {/* <Link
        onClick={() => setisVisible(false)}
        to={ROUTE_PATH.LANDING_SERVICE_INTRODUCTION}
      > */}
      {formatMessage(intl, LANDING_HEADER_DOCUMENTATION)}
      <img src={whiteArrowIcon} alt="" />
      {/* </Link> */}
    </Modal>
  );
};

export default injectIntl(LandingHeaderModal);
