import { RouteObject } from "react-router-dom";
import LoginPage from "./login/login.page";

import { isSnapshot, ROUTE_PATH } from "../constants";
import Layout from "../components/Layout/Layout";
import Home from "../components/Home";
import RpcPanel from "../components/RpcPanel/RpcPanel";
import RegisterPage from "./register/register.page";
import AuthLayout, {
  AythLayoutP,
  WAuthLayoutUAR,
} from "../components/AuthLayout";
import Profile from "../components/Profile/Profile";
import ChangeEmailPage from "./changeEmail/changeEmail.page";
import NotFound from "./404/404.page";
import WrapperForModal from "../components/WrapperForModal/WrapperForModal";
import VerificationRegistrationPage from "./verificationRegistration/verificationRegistration.page";
import PaddleWidget from "../components/PaddleWidget";

const authRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.LOGIN,
    element: (
      <AuthLayout>
        <WrapperForModal>
          <LoginPage />
        </WrapperForModal>
      </AuthLayout>
    ),
  },
  {
    path: ROUTE_PATH.REGISTER,
    element: (
      <AuthLayout>
        <WrapperForModal>
          <RegisterPage />
        </WrapperForModal>
      </AuthLayout>
    ),
  },
  {
    path: ROUTE_PATH.CONFIRMATION_EMAIL,
    element: (
      <AythLayoutP>
        <WrapperForModal>
          <VerificationRegistrationPage />
        </WrapperForModal>
      </AythLayoutP>
    ),
  },
];

const dashboardRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.ROOT,
    element: <Layout />,
    children: [
      {
        path: ROUTE_PATH.ROOT,
        element: <Home />,
      },
      {
        path: ROUTE_PATH.RPC_PANEL,
        element: <RpcPanel />,
      },
      {
        path: ROUTE_PATH.PROFILE,
        element: <Profile />,
      },
    ],
  },
  {
    path: ROUTE_PATH.CHANGE_EMAIL,
    element: (
      <WAuthLayoutUAR>
        <WrapperForModal>
          <ChangeEmailPage />
        </WrapperForModal>
      </WAuthLayoutUAR>
    ),
  },
  {
    path: ROUTE_PATH.PAYMENT,
    element: (
      <AythLayoutP>
        <WrapperForModal>
          {isSnapshot ? null : (
            <PaddleWidget
              className="pages__payment"
              onClose={() => {}}
              onComplete={() => {}}
            />
          )}
        </WrapperForModal>
      </AythLayoutP>
    ),
  },
];

// const landingRoutes: RouteObject[] = [
//   {
//     path: ROUTE_PATH.LANDING,
//     element: <LandingLayout />,
//     children: [
//       {
//         path: ROUTE_PATH.LANDING,
//         element: <LandingPage />,
//       },
//       {
//         path: ROUTE_PATH.LANDING_OUR_SOLUTION,
//         element: <LandingOurSolutionPage />,
//       },
//     ],
//   },
// ];

// const landingDocumentationRoutes: RouteObject[] = [
//   {
//     path: ROUTE_PATH.LANDING_DOCUMENTATION,
//     element: <LandingDocumentationLayout />,
//     children: [
//       {
//         path: ROUTE_PATH.LANDING_SERVICE_INTRODUCTION,
//         element: (
//           <LandingDocumentationPage>
//             <ServiceIntroduction />
//           </LandingDocumentationPage>
//         ),
//       },
//       {
//         path: `${ROUTE_PATH.LANDING_PRICING}/:variable-for-scrolling?`,
//         element: (
//           <LandingDocumentationPage>
//             <LandingPricing />
//           </LandingDocumentationPage>
//         ),
//       },
//       {
//         path: ROUTE_PATH.LANDING_API_DOCUMENTATION,
//         element: (
//           <LandingDocumentationPage>
//             <LandingApiDocumentation />
//           </LandingDocumentationPage>
//         ),
//       },
//       {
//         path: ROUTE_PATH.LANDING_SHORT_OVERVIEW,
//         element: (
//           <LandingDocumentationPage>
//             <LandingShortOverview />
//           </LandingDocumentationPage>
//         ),
//       },
//       {
//         path: ROUTE_PATH.LANDING_TERMS_OF_SERVICE, //Terms of Service
//         element: (
//           <LandingDocumentationPage>
//             <LandingTermsOfService />
//           </LandingDocumentationPage>
//         ),
//       },
//       {
//         path: ROUTE_PATH.LANDING_PRIVACY_POLICY, //Privacy Policy
//         element: (
//           <LandingDocumentationPage>
//             <LandingPrivacyPolicy />
//           </LandingDocumentationPage>
//         ),
//       },
//     ],
//   },
// ];

const route404 = {
  path: "*",
  element: <NotFound />,
};

export const getRoutes = (): RouteObject[] => {
  // if (isWaitlistOnly) {
  //   return [
  //     ...landingDocumentationRoutes,
  //      ...landingRoutes,
  //     route404];
  // }

  return [
    // ...landingDocumentationRoutes,
    // ...landingRoutes,
    ...dashboardRoutes,
    ...authRoutes,
    route404,
  ];
};
