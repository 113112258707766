/* eslint-disable no-new-object */
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { FC } from "react";
import "./ButtonGroupsSmooth.sass";
import ButtonGroupSmooth from "./ButtonGroupSmooth/ButtonGroupSmooth";
import cn from "classnames";

export type ButtonGroupsSmoothOption = {
  title: string;
  value: string;
  image?: string;
};

export type Props = {
  className?: string;
  value: string;
  options: ButtonGroupsSmoothOption[];
  onChange: (arg0: string) => void;
};

type TButtonRefs = {
  [key: string]: React.RefObject<HTMLButtonElement>;
};

const ButtonGroupsSmooth: FC<Props> = ({
  options,
  className = "",
  value,
  onChange,
}) => {
  const [buttonRefs, setButtonRefs] = useState<TButtonRefs>({});
  const [disabledButton, setDisabledButton] =
    useState<React.RefObject<HTMLButtonElement>>();
  const [screenTitle, setScreenTitle] = useState<string>("");
  const parentRef = useRef<HTMLDivElement>(null);
  const screenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      screenRef &&
      screenRef.current &&
      disabledButton &&
      disabledButton.current &&
      parentRef &&
      parentRef.current
    ) {
      screenRef.current.className = `${disabledButton.current.className} ${screenRef.current.className}`;
      screenRef.current.setAttribute(
        "style",
        `
          position: absolute;
          display: block;
          cursor: default;
          left: ${disabledButton.current.getBoundingClientRect().left -
        parentRef.current.getBoundingClientRect().left
        }px; 
          height: ${disabledButton.current.getBoundingClientRect().height - 4
        }px;
          width: ${disabledButton.current.getBoundingClientRect().width}px;
           `
      );
      //  height: ${disabledButton.current.getBoundingClientRect().height}px;
      // screenRef.style.top = disabledButton.top + 100 + 'px';
    }
  }, [disabledButton]);

  const processPressedTab = (value: string) => {
    onChange(value);
  };

  const getButtonRef = useCallback((
    title: string,
    ref: MutableRefObject<HTMLButtonElement>) => {
    const newButtonRefs = new Object(buttonRefs) as TButtonRefs;
    newButtonRefs[ref.current.name] = ref;
    setButtonRefs({ ...newButtonRefs } as TButtonRefs);
    if (ref.current.disabled) {
      setDisabledButton(ref);
      setScreenTitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={parentRef}
      className={cn({
        buttonGroupsSmooth: true,
        [className]: className,
      })}
    >
      {options.map((item: ButtonGroupsSmoothOption) => (
        <ButtonGroupSmooth
          key={item.title + item.value}
          title={item.title}
          value={item.value}
          checked={value === item.value}
          onPress={processPressedTab}
          getButtonRef={getButtonRef}
        />
      ))}
      <div
        className={cn({
          buttonGroupsSmooth_screen: true,
        })}
        ref={screenRef}
      >
        {screenTitle}
      </div>
    </div>
  );
};

export default ButtonGroupsSmooth;
