import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  addAccessTokenToHeaders,
  getRefreshToken,
  removeToken,
  saveToken,
} from "../utils/utils";
import { isSnapshot } from "../constants";
import { Mutex } from "async-mutex";
import { PayloadAction } from "@reduxjs/toolkit";

const refresh = async () => {
  return axios({
    method: "get",
    url: "/api/auth/refresh",
    headers: getRefreshToken(),
  })
    .then((res) => res)
    .catch((err) => err);
};

const mutex = new Mutex();

const customBaseQuery =
  (
    { baseUrl, apiName }: { baseUrl: string; apiName: string } = {
      baseUrl: "",
      apiName: "",
    }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }, { dispatch }) => {
    const requestParams = {
      url: baseUrl + url,
      method,
      data,
      params,
      headers: addAccessTokenToHeaders(headers),
    };

    const resetAction: PayloadAction<undefined, `${string}/resetApiState`> = {
      type: `${apiName}/resetApiState`,
      payload: undefined,
    };

    try {
      if (isSnapshot) throw new Error("It is Snapshot");
      await mutex.waitForUnlock();
      const result = await axios(requestParams);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (
        err.response?.status === 401 &&
        localStorage.getItem("refreshToken")
      ) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          const refreshResult = await refresh().finally(() => {
            release();
          });
          if (refreshResult.data) {
            const {
              data: { accessToken, refreshToken },
            } = refreshResult;
            saveToken({ accessToken, refreshToken });
            const newConfig = {
              ...requestParams,
              headers: addAccessTokenToHeaders(headers),
            };

            await mutex.waitForUnlock();
            const response = await axios(newConfig);
            return { data: response.data };
          } else {
            removeToken();
            dispatch(resetAction);
            return {
              error: err.response.data,
            };
          }
        }
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default customBaseQuery;
