import { FC } from "react";
import { injectIntl } from "react-intl";
import "./DocumentationSideBar.sass";
import { Link, useLocation } from "react-router-dom";
// import whiteArrowIcon from "../../resources/images/white_arrow.svg";
import { IBaseComponentProps } from "../../types";
import formatMessage from "../../utils/formatMessage";
import {
  LANDING_FOOTER_PRIVACY_POLICE,
  LANDING_FOOTER_TERMS_OF_SERVICE,
} from "../LandingFooter/LandingFooter";
import { DOCUMENTATION_PATH, ROUTE_PATH } from "../../constants";

type TProps = {
  isModalVersion?: boolean;
  closeModal?: () => void;
} & IBaseComponentProps;

const DocumentationSideBar: FC<TProps> = ({
  intl,
  // isModalVersion = false,
  closeModal,
}) => {
  const { pathname } = useLocation();

  return (
    <div onClick={closeModal} className="documentation-side-bar">
      <span
        className={`documentation-side-bar__item 
        ${
          pathname.includes(DOCUMENTATION_PATH) &&
          "documentation-side-bar__selected"
        }
        `}
      >
        <Link to={ROUTE_PATH.LANDING_SERVICE_INTRODUCTION}>
          Service Introduction
        </Link>
      </span>
      <span
        className={`documentation-side-bar__item
      ${pathname.includes("pricing") && "documentation-side-bar__selected"}
      `}
      >
        <Link to={ROUTE_PATH.LANDING_PRICING}>
          Pricing and Payment Methods
          {/* {isModalVersion && <img
            className={`${pathname.includes("pricing") && "documentation-side-bar__transform-img"}`}
            src={whiteArrowIcon}
            alt="" />
          } */}
        </Link>
      </span>
      {/* {isModalVersion && (<div>
        <span className={`documentation-side-bar__subitem
         ${pathname.includes("select-tariff") && "documentation-side-bar__selected"}`}>
          <Link to={`${ROUTE_PATH.LANDING_PRICING}/:select-tariff`}>
            Select Your Tariff Plan
          </Link>
        </span>
        <span className={`documentation-side-bar__subitem
         ${pathname.includes("payment-acceptance") && "documentation-side-bar__selected"}`}>
          <Link to={`${ROUTE_PATH.LANDING_PRICING}/:payment-acceptance`}>
            Payment Acceptance
          </Link>
        </span>
        <span className={`documentation-side-bar__subitem
         ${pathname.includes("service-activation") && "documentation-side-bar__selected"}`}>
          <Link to={`${ROUTE_PATH.LANDING_PRICING}/:service-activation`}>
            Service Activation
          </Link>
        </span></div>)} */}
      {/* <span className="documentation-side-bar__item">
        <Link to={ROUTE_PATH.LANDING_API_DOCUMENTATION}>
          Getting Started
          <img
            className={`${(
              pathname.includes("api") ||
              pathname.includes("terms-of-service") ||
              pathname.includes("privacy-policy") ||
              pathname.includes("short-overview")
            ) &&
              "documentation-side-bar__transform-img"
              }`}
            src={whiteArrowIcon}
            alt=""
          />
        </Link>
      </span> */}
      <span
        className={`documentation-side-bar__item
     ${pathname.includes("api") && "documentation-side-bar__selected"}
      `}
      >
        <Link to={ROUTE_PATH.LANDING_API_DOCUMENTATION}>API Documenation</Link>
      </span>
      <span
        className={`documentation-side-bar__subitem 
        ${
          pathname.includes("terms-of-service") &&
          "documentation-side-bar__selected"
        }
        `}
      >
        <Link to={ROUTE_PATH.LANDING_TERMS_OF_SERVICE}>
          {formatMessage(intl, LANDING_FOOTER_TERMS_OF_SERVICE)}
        </Link>
      </span>
      <span
        className={`documentation-side-bar__subitem 
        ${
          pathname.includes("privacy-policy") &&
          "documentation-side-bar__selected"
        }
        `}
      >
        <Link to={ROUTE_PATH.LANDING_PRIVACY_POLICY}>
          {formatMessage(intl, LANDING_FOOTER_PRIVACY_POLICE)}
        </Link>
      </span>
      {/* <span style={{ color: "gray", cursor: "default" }} className={`documentation-side-bar__subitem 
        ${pathname.includes("short-overview") && "documentation-side-bar__selected"}
        `}>
        <Link to={ROUTE_PATH.LANDING_SHORT_OVERVIEW}>
        Short Overview
        </Link>
      </span> */}
    </div>
  );
};

export default injectIntl(DocumentationSideBar);
