export const OpenedEye = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49159 6.05165C4.94992 4.64748 7.09742 3.33331 9.99992 3.33331C12.9024 3.33331 15.0499 4.64748 16.5083 6.05165C17.5298 7.04346 18.3754 8.2016 19.0091 9.47665C19.1699 9.80915 19.1699 10.1908 19.0091 10.5225C18.3758 11.7978 17.5304 12.9562 16.5091 13.9483C15.0499 15.3525 12.9024 16.6666 9.99992 16.6666C7.09742 16.6666 4.94992 15.3525 3.49159 13.9483C2.47006 12.9565 1.62443 11.7983 0.990753 10.5233C0.911528 10.3604 0.870361 10.1816 0.870361 10.0004C0.870361 9.81922 0.911528 9.64041 0.990753 9.47748C1.62409 8.20222 2.47028 7.0438 3.49159 6.05165ZM9.99992 12.5C10.663 12.5 11.2988 12.2366 11.7677 11.7677C12.2365 11.2989 12.4999 10.663 12.4999 9.99998C12.4999 9.33694 12.2365 8.70105 11.7677 8.23221C11.2988 7.76337 10.663 7.49998 9.99992 7.49998C9.33688 7.49998 8.70099 7.76337 8.23215 8.23221C7.76331 8.70105 7.49992 9.33694 7.49992 9.99998C7.49992 10.663 7.76331 11.2989 8.23215 11.7677C8.70099 12.2366 9.33688 12.5 9.99992 12.5Z"
        fill="white"
      />
    </svg>
  );
};

export const ClosedEye = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37944 0.495558C1.13536 0.251481 0.739636 0.251481 0.495558 0.495558C0.251481 0.739636 0.251481 1.13536 0.495558 1.37944L4.01212 4.89601C1.41059 7.09066 0 10 0 10C0 10 3.63636 17.5 10 17.5C11.9172 17.5 13.5868 16.8193 14.9841 15.868L18.6206 19.5044C18.8646 19.7485 19.2604 19.7485 19.5044 19.5044C19.7485 19.2604 19.7485 18.8646 19.5044 18.6206L1.37944 0.495558ZM11.7533 12.6372L7.41282 8.29671C7.07336 8.79665 6.875 9.40017 6.875 10.05C6.875 11.7759 8.27411 13.175 10 13.175C10.6498 13.175 11.2534 12.9767 11.7533 12.6372Z"
        fill="#424A51"
      />
      <path
        d="M20 10C20 10 19.1101 11.8354 17.4404 13.6903L13.0292 9.27917C12.7485 8.17262 11.8774 7.30152 10.7708 7.0208L6.87481 3.12477C7.82726 2.7362 8.87068 2.5 10 2.5C16.3636 2.5 20 10 20 10Z"
        fill="#424A51"
      />
    </svg>
  );
};
