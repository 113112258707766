import { FC, useContext } from "react";
import { injectIntl } from "react-intl";
import "./LandingPackagesBlock.sass";
import "./LandingPackagesBlockOld.sass";
import TariffCard from "../TariffCard/TariffCard";
import formatMessage from "../../utils/formatMessage";
import { Button } from "antd";
import { ROUTE_PATH, isSnapshot, isWaitlistOnly } from "../../constants";
import { Link } from "react-router-dom";
import { RegisterModalContext } from "../LandingLayout/LandingLayout";
import { IBaseComponentProps } from "../../types";
import {
  HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION,
  HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_PRO,
  HOME_PAGE_MY_PLANITEM_SUBTITLE,
  HOME_PAGE_MY_PLANITEM_SUBTITLE_HISTORICAL,
  HOME_PAGE_MY_PLANITEM_SUBTITLE_PRO,
} from "../Home/Home";
import useScreenWidth from "../../hooks/useScreenWidth";
import { useGetMeQuery } from "../../api/reg-service.api";
import WindowWithEmailAddress from "../../hocs/windowWithEmailAddress/windowWithEmailAddress";

type TProps = {} & IBaseComponentProps;

const LANDING_PACKAGES_BLOCK_TITLE = { id: "landing-packages-block.title" };
const LANDING_PACKAGES_BLOCK_TITLE_DESCRIPTION = {
  id: "landing-packages-block.title-description",
};
const LANDING_PACKAGES_BLOCK_BEGINNER = {
  id: "landing-packages-block.beginner",
};
const LANDING_PACKAGES_BLOCK_BEGINNER_BUTTON = {
  id: "landing-packages-block.beginner-button",
};
const LANDING_PACKAGES_BLOCK_BEGINNER_DESCRIPTION = {
  id: "landing-packages-block.beginner-description",
};
const LANDING_PACKAGES_BLOCK_STANDART = {
  id: "landing-packages-block.standart",
};
// const LANDING_PACKAGES_BLOCK_STANDART_BUTTON = {
//   id: "landing-packages-block.standart-button",
// };
const LANDING_PACKAGES_BLOCK_STANDART_DESCRIPTION = {
  id: "landing-packages-block.standart-description",
};
const LANDING_PACKAGES_BLOCK_TAILORED = {
  id: "landing-packages-block.tailored",
};
export const LANDING_PACKAGES_BLOCK_CONTACT_US_BUTTON = {
  id: "landing-packages-block.contact-us-button",
};
export const LANDING_PACKAGES_BLOCK_GET_NEW_STANDARD_BUTTON = {
  id: "landing-packages-block.get-new-standard-button",
};
const LANDING_PACKAGES_BLOCK_TAILORED_DESCRIPTION = {
  id: "landing-packages-block.tailored-description",
};
const LANDING_PACKAGES_BLOCK_ITEM_RPC = {
  id: "landing-packages-block.item-rpc",
};
const LANDING_PACKAGES_BLOCK_CUSTOM_ITEM_RPC = {
  id: "landing-packages-block.custom-item-rpc",
};
const LANDING_PACKAGES_BLOCK_CUSTOM_LIMITS_RPC = {
  id: "landing-packages-block.custom-limits",
};
const LANDING_PACKAGES_BLOCK_ITEM_WEBHOOCK = {
  id: "landing-packages-block.item-webhoock",
};
const LANDING_PACKAGES_BLOCK_ARCHIVE_DATA = {
  id: "landing-packages-block.archive-data",
};
const LANDING_PACKAGES_TAX = {
  id: "landing-packages-block.tax",
};
// const LANDING_PACKAGES_BLOCK_ITEM_TRIAL = {
//   id: "landing-packages-block.item-trial",
// };
// const LANDING_PACKAGES_BLOCK_ITEM_SUPPORT = {
//   id: "landing-packages-block.item-support",
// };
const HOME_PAGE_MY_PLAN_TALK = {
  id: "plan-card.lets-talk",
};

const PLANCARD_MONTH = { id: "plan-card.month" };

const LandingPackagesBlock: FC<TProps> = ({ intl }) => {
  const { data: user } = useGetMeQuery();
  const { setIsOpen } = useContext(RegisterModalContext);
  const isSmallScreen = useScreenWidth();

  const renderButton = (buttonName: string) =>
    isWaitlistOnly ? (
      <Button
        type="primary"
        className="tariff-card_button"
        onClick={() => setIsOpen(true)}
      >
        {buttonName}
      </Button>
    ) : (
      <Link to={ROUTE_PATH.REGISTER}>
        <Button type="primary" className="tariff-card_button">
          {buttonName}
        </Button>
      </Link>
    );

  const getNewStandardHref = user
    ? `${ROUTE_PATH.ROOT}`
    : `${ROUTE_PATH.REGISTER}`;

  const cards = [
    <TariffCard
      additionalDescription={formatMessage(
        intl,
        HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION
      )}
      subTitle={formatMessage(intl, HOME_PAGE_MY_PLANITEM_SUBTITLE)}
      button={renderButton(
        formatMessage(intl, LANDING_PACKAGES_BLOCK_BEGINNER_BUTTON)
      )}
      title={formatMessage(intl, LANDING_PACKAGES_BLOCK_BEGINNER)}
      description={formatMessage(
        intl,
        LANDING_PACKAGES_BLOCK_BEGINNER_DESCRIPTION
      )}
      price={"0"}
      items={[
        `10 ${formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_RPC)}`,
        // formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_СREDITS),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_WEBHOOCK),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_ARCHIVE_DATA),
      ]}
    />,
    <TariffCard
      isPopular
      additionalDescription={formatMessage(
        intl,
        HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_PRO
      )}
      subTitle={formatMessage(intl, HOME_PAGE_MY_PLANITEM_SUBTITLE_PRO)}
      // button={renderButton(
      //   formatMessage(intl, LANDING_PACKAGES_BLOCK_STANDART_BUTTON)
      // )}

      button={
        <a href={getNewStandardHref}>
          <Button type="primary" className="tariff-card_button">
            {formatMessage(
              intl,
              LANDING_PACKAGES_BLOCK_GET_NEW_STANDARD_BUTTON
            )}
          </Button>
        </a>
      }
      title={formatMessage(intl, LANDING_PACKAGES_BLOCK_STANDART)}
      description={formatMessage(
        intl,
        LANDING_PACKAGES_BLOCK_STANDART_DESCRIPTION
      )}
      renderPrice={() => (
        <>
          <div className="old-packages-block__price">
            <span className="tariff-card_price">49$</span>
            <span className="tariff-card_fart">
              {" "}
              /{formatMessage(intl, PLANCARD_MONTH)}
            </span>
          </div>
          <div className="old-packages-block__price-hint">
            {formatMessage(intl, LANDING_PACKAGES_TAX)}
          </div>
        </>
      )}
      items={[
        // formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_TRIAL),
        `100 ${formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_RPC)}`,
        // formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_СREDITS),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_WEBHOOCK),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_ARCHIVE_DATA),
        // formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_SUPPORT),
      ]}
    />,
    <TariffCard
      // additionalDescription={formatMessage(intl, HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_TAILORED)}
      subTitle={formatMessage(intl, HOME_PAGE_MY_PLANITEM_SUBTITLE_HISTORICAL)}
      button={
        // <a href={`mailto:${CONTACT_EMAIl}`}>
        <WindowWithEmailAddress>
          <Button type="primary" className="tariff-card_button">
            {formatMessage(intl, LANDING_PACKAGES_BLOCK_CONTACT_US_BUTTON)}
          </Button>
        </WindowWithEmailAddress>
        // </a>
      }
      title={formatMessage(intl, LANDING_PACKAGES_BLOCK_TAILORED)}
      description={formatMessage(
        intl,
        LANDING_PACKAGES_BLOCK_TAILORED_DESCRIPTION
      )}
      renderPrice={() => (
        <div className="old-packages-block__price-text">
          {formatMessage(intl, HOME_PAGE_MY_PLAN_TALK)}
        </div>
      )}
      items={[
        formatMessage(intl, LANDING_PACKAGES_BLOCK_CUSTOM_ITEM_RPC),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_CUSTOM_LIMITS_RPC),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_ITEM_WEBHOOCK),
        formatMessage(intl, LANDING_PACKAGES_BLOCK_ARCHIVE_DATA),
      ]}
    />,
  ];

  return (
    <div className="old-packages-block_wrapper">
      <div className="old-packages-block">
        <h2>{formatMessage(intl, LANDING_PACKAGES_BLOCK_TITLE)}</h2>
        <p className="old-packages-block__description">
          {formatMessage(intl, LANDING_PACKAGES_BLOCK_TITLE_DESCRIPTION)}
        </p>
        <div className="old-packages-block_cards">
          {isSmallScreen && !isSnapshot
            ? [cards[1], cards[0], cards[2]]
            : cards}
        </div>
      </div>
    </div>
  );
};
export default injectIntl(LandingPackagesBlock);
