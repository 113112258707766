import cn from "classnames";

import "./RegisterModal.sass";
import { IntlShape, injectIntl } from "react-intl";
import { Button, Form, Typography } from "antd";
import RegisterForm from "../RegisterForm";
import ReactModal from "react-modal";
import formatMessage from "../../utils/formatMessage";
import { useState } from "react";

interface IProps extends ReactModal.Props {
  className?: string;
  intl: IntlShape;
}

const TITLE = { id: "register-modal.title" };
const SUBTITLE1 = { id: "register-modal.subtitle1" };
const SUBTITLE2 = { id: "register-modal.subtitle2" };
const SUCCESS = { id: "register-modal.success" };
const DONE = { id: "register-modal.done" };
const GREAT = { id: "register-modal.great" };
const CONFIRM = { id: "register-modal.confirm" };
const CANCEL = { id: "register-modal.cancel" };

enum EComponent {
  FORM,
  SUCCESS_MESSAGE,
}

const RegisterModal: React.FC<IProps> = ({
  className,
  intl,
  ...modalProps
}) => {
  const [component, setComponent] = useState(EComponent.FORM);

  const renderComponent = () => {
    let renderComponent;
    switch (component) {
      case EComponent.FORM:
        renderComponent = (
          <RegisterForm
            className="register-modal__form"
            onSuccessCb={() => {
              setComponent(EComponent.SUCCESS_MESSAGE);
            }}
            title={
              <div className="register-modal__text">
                <button onClick={modalProps.onRequestClose} className="register-modal__button_close"></button>
                <Typography.Title level={4}>
                  {formatMessage(intl, TITLE)}
                </Typography.Title>
                <p>{formatMessage(intl, SUBTITLE1)}</p>
                <p>{formatMessage(intl, SUBTITLE2)}</p>
              </div>
            }
            renderButtons={(props) => (
              <>
                <Form.ErrorList
                  className="register-modal__error-list"
                  helpStatus={props.submitError ? "error" : "success"}
                  help={props.submitError}
                />
                <Form.Item className="register-modal__buttons">
                  <Button onClick={modalProps.onRequestClose}>
                    {formatMessage(intl, CANCEL)}
                  </Button>
                  <Button
                    disabled={props.disabled}
                    type="primary"
                    htmlType="submit"
                  >
                    {formatMessage(intl, CONFIRM)}
                  </Button>
                </Form.Item>
              </>
            )}
          />
        );
        break;
      case EComponent.SUCCESS_MESSAGE:
        renderComponent = (
          <div className="register-modal__success">
            <div className="register-modal__text">
              <Typography.Title level={2}>
                {formatMessage(intl, DONE)}
              </Typography.Title>
              <p>{formatMessage(intl, SUCCESS)}</p>
            </div>
            <Button onClick={modalProps.onRequestClose} type="primary">
              {formatMessage(intl, GREAT)}
            </Button>
          </div>
        );
        break;
      default:
        renderComponent = null;
    }
    return renderComponent;
  };

  return (
    <ReactModal
      className={cn("register-modal", className)}
      style={{
        overlay: {
          background: "rgba(0, 0, 0, 0.45)",
          zIndex: "1",
        },
      }}
      {...modalProps}
    >
      {renderComponent()}
    </ReactModal>
  );
};

export default injectIntl(RegisterModal);
