import { FC } from "react";
import { injectIntl } from "react-intl";
import logo from "../../resources/images/logo.svg";
import "./LogoBlock.sass";
import { Link } from "react-router-dom";
import ScrollToTopByClick from "../../hocs/scrollToTopByClick";
import { IBaseComponentProps } from "../../types";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";

type TProps = {
  className?: string
} & IBaseComponentProps;

const LogoBlock: FC<TProps> = ({ intl, className }) => {
  const { landingLink } = useGetLandingLinkByAuthorizedUser()
  return (
    <ScrollToTopByClick>
      <Link style={landingLink ? { all: "unset" } : { pointerEvents: "none", all: "unset" }} to={landingLink || ""}>
        <button className="logo-block" title="landing" >
          <img className={`logo-block__img ${className}`} src={logo} alt="logo" />
        </button>
      </Link>
    </ScrollToTopByClick>
  );
};
export default injectIntl(LogoBlock);
