import React, { PropsWithChildren, createContext, useState } from "react";
import "./LandingLayout.sass";
import useToken from "antd/es/theme/useToken";
import LandingHeader from "../LandingHeader/LandingHeader";
import LandingFooter from "../LandingFooter/LandingFooter";
import { Outlet } from "react-router-dom";
import RegisterModal from "../RegisterModal";
// import HeaderLine from "../HeaderLine/HeaderLine";

interface IRegisterModalContext {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const RegisterModalContext = createContext<IRegisterModalContext>({
  isOpen: false,
  setIsOpen: () => { },
});

const LandingLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [, token] = useToken();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <RegisterModalContext.Provider
      value={{ isOpen: modalIsOpen, setIsOpen: setModalIsOpen }}
    >
      <div
        style={{
          color: token.colorPrimary,
        }}
        className="landing-layout"
      >
        <LandingHeader />
        {/* <HeaderLine /> */}
        <Outlet />
        <LandingFooter />
        <RegisterModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
        />
      </div>
    </RegisterModalContext.Provider>
  );
};

export default LandingLayout;
