import {
  Popover, Tooltip
} from "antd";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import "./windowWithEmailAddress.sass"
import { CONTACT_EMAIl } from "../../constants";
import { ReactComponent as CopyOutlined } from "../../resources/images/copy_outlined_transparent.svg";
import copyingToClipboard from "../../utils/copyingToClipboard";
import arrow from "../../resources/images/cross_white_header.svg";


type TProps = {
  intl: IntlShape;
  className?: string;
} & PropsWithChildren
const WindowWithEmailAddress: FC<TProps> = ({ intl, children, className }) => {

  const [isAnimate, setIsAnimate] = useState(false);
  const [isPopupShow, setIsPopupShow] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopupShow(newOpen);
  };

  const click = () => {
    setIsAnimate(true)
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isAnimate) {
      timer = setTimeout(() => {
        setIsAnimate(false)
      }, 600)
    }
    return () => {
      clearTimeout(timer);
    }
  }, [isAnimate]);

  const closePopup = () => {
    setIsPopupShow(false)
  }

  return (
    <Popover
      placement="bottom"
      content={
        <div className="window-with-email-address__popup_content">
          <h3 className="window-with-email-address__popup_title">
            Feel free to reach out to us
            <img
              onClick={closePopup}
              className="window-with-email-address__closeicon"
              width={16}
              height={16}
              src={arrow}
              alt=""
            />
          </h3>
          <div className="window-with-email-address__popup_line"></div>
          <p className="window-with-email-address__popup_mail">
            {CONTACT_EMAIl}
            <Tooltip trigger={["click"]} title="copied">
              <button
                className="window-with-email-address__popup_copy"
                onClick={() => copyingToClipboard(CONTACT_EMAIl)}
              >
                <CopyOutlined />
              </button>
            </Tooltip>

          </p>
          <a className="window-with-email-address__popup_link" href={`mailto:${CONTACT_EMAIl}`}>
            <button onClick={click} className={`window-with-email-address__popup_button ${isAnimate && "window-with-email-address__animate"}`}>
              Send Us an Email
            </button>
          </a>
        </div>
      }
      className={`window-with-email-address__popup ${className}`}
      rootClassName="window-with-email-address__popup_root"
      arrow={false}
      trigger="click"
      open={isPopupShow}
      onOpenChange={handleOpenChange}
    >
      <div>
        {children}
      </div>

    </Popover>
  )
}

export default injectIntl(WindowWithEmailAddress)