import LoginForm from "../../components/LoginForm";
import "./login.page.sass";

const LoginPage: React.FC = () => {
  return (
    <div className="login-page">
      <div className="login-page__card">
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
