import { notification } from "antd";

type NotificationType = "success" | "error" | "info" | "warning";

const notificationView = (type: NotificationType, message: string) => {
  notification[type]({
    className: "notification",
    message: message,
  });
};
export default notificationView;
