import { FC, useMemo } from "react";

type TProps = {
  children: string;
  ratio?: number
  numberOfCharactersIsNotAllowed: number;
  defaultSize: number
}

const DependsOnLengthSize: FC<TProps> = ({ children, defaultSize, numberOfCharactersIsNotAllowed, ratio = 1 }) => {
  const size = useMemo(() => {
    if (children.length >= numberOfCharactersIsNotAllowed) {
      return defaultSize * ratio
    }
    return defaultSize

  }, [children.length, numberOfCharactersIsNotAllowed, defaultSize, ratio])

  return (
    <span style={{
      fontSize: size,
      lineHeight: "normal"
    }}>
      {children}
    </span>
  )
}
export default DependsOnLengthSize