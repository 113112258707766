import { combineReducers } from "@reduxjs/toolkit";
import pendingsReducer from "./pendings.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const store = combineReducers({
  pendings: persistReducer(persistConfig, pendingsReducer),
});

export default store;
