import { useCallback, useEffect, useMemo, useState } from "react";

export const useTimer = (countdownS: number) => {
  const [count, setCount] = useState(countdownS);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  const counter = useCallback(() => {
    setCount((val) => val - 1);
  }, []);

  const start = useCallback(() => {
    setIntervalId(
      setInterval(() => {
        counter();
      }, 1000)
    );
  }, [counter]);

  const stop = useCallback(() => {
    clearInterval(intervalId);
  }, [intervalId]);

  const reset = useCallback(() => {
    stop();
    setCount(countdownS);
  }, [countdownS, stop]);

  const formattedTime = useMemo(() => {
    return new Date(count * 1000).toISOString().slice(14, 19);
  }, [count]);

  useEffect(() => {
    if (count === 0) stop();
  }, [count, intervalId, stop]);

  return {
    count,
    start,
    stop,
    formattedTime,
    reset,
  };
};
