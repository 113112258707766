import React, { FC } from "react";
import SideBar from "../SideBar/SideBar";
import "./Layout.sass";

import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import withUnautorizedRedirect from "../../hocs/withUnautorizedRedirect";
import useScreenWidth from "../../hooks/useScreenWidth";

export const Layout: FC = () => {
  const isSmallScreen = useScreenWidth();

  return (
    <div className="layout">
      {!isSmallScreen && <SideBar />}
      <div className="layout__content">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default withUnautorizedRedirect(Layout);
