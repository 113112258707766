import { FC, ReactNode, useMemo } from "react";
import { injectIntl } from "react-intl";
import "./NewPlanCard.sass";
import useToken from "antd/es/theme/useToken";
import { Button, Tooltip, Typography } from "antd";
import check from "../../resources/images/circle_check_violet.svg";
import { IBaseComponentProps } from "../../types";
import DependsOnLengthSize from "../DependsOnLengthSize/DependsOnLengthSize";
import formatMessage from "../../utils/formatMessage";
import WindowWithEmailAddress from "../../hocs/windowWithEmailAddress/windowWithEmailAddress";
import { CONTACT_EMAIl, EXTERNAL_LINKS } from "../../constants";
import ButtonGroupsSmooth, {
  ButtonGroupsSmoothOption,
} from "../ButtonGroupsSmooth/ButtonGroupsSmooth";
import { countLettersBeforeSecondUpper } from "../../utils/utils";

type TProps = {
  title: string;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  checkItems?: (string | { text: string; hint: string })[];
  renderPrice?: () => ReactNode;
  price?: string;
  isButtonLinkToEmail?: boolean;
  buttonType?: "default" | "link" | "text" | "primary" | "dashed";
  isActiveTailoredPlan?: boolean;
  buttonClick?: () => void;
  selectedPaidTariffOption?: ButtonGroupsSmoothOption;
  paidTariffsOptions?: ButtonGroupsSmoothOption[];
  onButtonGroupsSmoothChange?: (e: string) => void;
  isBeastMode?: boolean;
} & IBaseComponentProps;

export const PLANCARD_MONTH = { id: "plan-card.month" };

const NewPlanCard: FC<TProps> = ({
  title,
  className,
  checkItems,
  buttonTitle,
  price,
  buttonType,
  buttonClick,
  buttonDisabled,
  isButtonLinkToEmail,
  intl,
  renderPrice,
  isActiveTailoredPlan,
  selectedPaidTariffOption,
  paidTariffsOptions,
  onButtonGroupsSmoothChange,

  isBeastMode,
}) => {
  const [, token] = useToken();
  const count = countLettersBeforeSecondUpper(title);

  const button = useMemo(() => {
    if (isButtonLinkToEmail)
      return (
        <WindowWithEmailAddress>
          <Button
            disabled={buttonDisabled}
            type={buttonType}
            className="new-plan-card__button"
            onClick={() => {
              window.gtag &&
                window.gtag("event", "request_tailored", {
                  // Consistent event name formatting
                  plan_name: "Tailored", // Plan name
                  page: window.location.pathname, // Page URL path
                  time: new Date().toISOString(), // Current time in ISO format
                });
            }}
          >
            {buttonTitle}
          </Button>
        </WindowWithEmailAddress>
      );
    else
      return (
        <Button
          disabled={buttonDisabled}
          onClick={buttonClick}
          type={buttonType}
          className="new-plan-card__button"
        >
          {buttonTitle}
        </Button>
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled, buttonTitle, buttonType, isButtonLinkToEmail]);

  const titleView = useMemo(() => {
    if (paidTariffsOptions && !title.includes("New Standard"))
      return (
        <Typography className="new-plan-card__title">
          {title.slice(0, count)}
          <p className="new-plan-card__title_chunk">{title.slice(count)}</p>
        </Typography>
      );
    else
      return <Typography className="new-plan-card__title">{title}</Typography>;
  }, [count, paidTariffsOptions, title]);

  const renderItem = (
    el: string | { text: string; hint: string },
    index: number
  ) => {
    if (typeof el !== "string")
      return (
        <div key={index} className="new-plan-card__check_blocks_block">
          <img src={check} alt="" />
          <Tooltip
            overlayClassName="new-plan-card__tooltip"
            trigger={["hover"]}
            title={el.hint}
          >
            <p
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {el.text}
            </p>
          </Tooltip>
        </div>
      );
    return (
      <div key={index} className="new-plan-card__check_blocks_block">
        <img src={check} alt="" />
        <p>{el}</p>
      </div>
    );
  };

  return (
    <div
      style={{ backgroundColor: token.colorPrimaryBg }}
      className={`new-plan-card ${className} `}
    >
      <div className="new-plan-card__content">
        <div className="new-plan-card__title_block">
          {titleView}
          {isBeastMode && (
            <p className="new-plan-card__beast_mode">Beast Mode</p>
          )}
        </div>
        {price && (
          <span className="new-plan-card__price">
            <DependsOnLengthSize
              defaultSize={48}
              numberOfCharactersIsNotAllowed={8}
              ratio={0.67}
            >
              {`${price || ""}$`}
            </DependsOnLengthSize>{" "}
            <span className="new-plan-card__price_part">
              /{formatMessage(intl, PLANCARD_MONTH)}
            </span>
          </span>
        )}
        {renderPrice && renderPrice()}
        {isActiveTailoredPlan && (
          <div className="new-plan-card__support">
            <span className="new-plan-card__support_title">Get Support</span>
            <span>
              Telegram:{" "}
              <a
                href={EXTERNAL_LINKS.TELEGRAM}
                target="_blank"
                rel="noreferrer"
                className="new-plan-card__support_link"
              >
                @instantnodes
              </a>
            </span>
            <span>
              Mail:{" "}
              <a
                href={`mailto:${CONTACT_EMAIl}`}
                className="new-plan-card__support_link"
              >
                support@instantnodes.io
              </a>
            </span>
          </div>
        )}
        <div className="new-plan-card__button_wrapper">
          {paidTariffsOptions &&
            selectedPaidTariffOption &&
            onButtonGroupsSmoothChange &&
            (paidTariffsOptions.length > 1 ? (
              <ButtonGroupsSmooth
                className="new-plan-card__button_groups"
                value={selectedPaidTariffOption.value}
                options={paidTariffsOptions}
                onChange={onButtonGroupsSmoothChange}
              />
            ) : (
              <div style={{ height: "20px" }}></div>
            ))}
          {buttonTitle ? button : <div style={{ height: "40px" }}></div>}
        </div>
      </div>
      <div className="new-plan-card__check_blocks">
        {checkItems && checkItems.map(renderItem)}
      </div>
    </div>
  );
};

export default injectIntl(NewPlanCard);
