import React, { FC, useState } from "react";
import "./Header.sass";
import useToken from "antd/es/theme/useToken";
import { injectIntl } from "react-intl";
import { useGetMeQuery, useGetProfileQuery } from "../../api/reg-service.api";
import useScreenWidth from "../../hooks/useScreenWidth";
import HeaderSideBarModal from "../HeaderSideBarModal/HeaderSideBarModal";
import AccountMenu from "../AccountMenu/AccountMenu";
import { IBaseComponentProps } from "../../types";

type TProps = {} & IBaseComponentProps;

const Header: FC<TProps> = () => {
  const isSmallScreen = useScreenWidth();

  const { data: profile } = useGetProfileQuery();

  useGetMeQuery((() => {})(), {
    pollingInterval: 60000,
    skipPollingIfUnfocused: true,
  });

  const [, token] = useToken();
  const [isSideBaVisible, setIsSideBarVisible] = useState(false);

  const hideSideBarModal = () => {
    setIsSideBarVisible(false);
  };
  const showSideBarModal = () => {
    setIsSideBarVisible(true);
  };

  return (
    <div
      style={{
        color: token.colorPrimary,
      }}
      className="header"
    >
      {isSmallScreen && (
        <button
          onClick={showSideBarModal}
          className="header__open_modal_button"
        ></button>
      )}
      <HeaderSideBarModal
        isVisible={isSideBaVisible}
        setisVisible={hideSideBarModal}
      />
      {profile && <AccountMenu profile={profile} />}
    </div>
  );
};
export default injectIntl(Header);
