import { Modal } from "antd";
import { FC } from "react";
import { injectIntl, IntlShape } from "react-intl";
import LogoBlock from "../LogoBlock/LogoBlock";
import "./LandingHeaderSideBarModal.sass";
import DocumentationSideBar from "../DocumentationSideBar/DocumentationSideBar";
import HeaderLine from "../HeaderLine/HeaderLine";
import MobileProfileWidget from "../MobileProfileWidget";

type TProps = {
  isVisible: boolean;
  setisVisible: (value: boolean) => void;
  intl: IntlShape;
};

const LandingHeaderSideBarModal: FC<TProps> = ({ intl, isVisible, setisVisible }) => {
  return (
    <Modal
      transitionName={""}
      width="100%"
      style={{ top: 0, bottom: 0, maxWidth: "100%", margin: 0 }}
      title={
        <div onClick={() => setisVisible(false)}>
          <LogoBlock className="landing-header-sidebar-modal__logo" />
        </div>
      }
      open={isVisible}
      // onOk={handleOk}
      closeIcon={<button className="landing-header-sidebar-modal__button_close"></button>}
      onCancel={() => setisVisible(false)}
      className="landing-header-sidebar-modal"
      footer={[]}
    >
      <HeaderLine className="landing-header-sidebar-modal__line" />
      <DocumentationSideBar isModalVersion closeModal={() => setisVisible(false)} />
      <MobileProfileWidget
        onClose={() => setisVisible(false)}
        className="landing-header-sidebar-modal__widget"
      />
    </Modal>
  )
}

export default injectIntl(LandingHeaderSideBarModal);