import React from "react";
import "./ProfileWidget.sass";
import cn from "classnames";
import { IBaseComponentProps } from "../../types";
import { injectIntl } from "react-intl";
import { useGetProfileQuery } from "../../api/reg-service.api";
import AccountMenu from "../AccountMenu/AccountMenu";
import { ROUTE_PATH, isWaitlistOnly } from "../../constants";
import { Button } from "antd";
import formatMessage from "../../utils/formatMessage";
import { Link } from "react-router-dom";

interface IProps extends IBaseComponentProps {
  setIsOpen?: (isOpen: boolean) => void;
}

const LANDING_HEADER_LOG_IN = { id: "landing-header.log-in" };
const LANDING_HEADER_BUTTON = { id: "landing-header.button" };
const LANDING_HEADER_SIGNUP = { id: "landing-header.sign-up" };

const ProfileWidget: React.FC<IProps> = ({ className, intl, setIsOpen }) => {
  const { data: profile, isSuccess } = useGetProfileQuery();
  return (
    <div className={cn("profile-widget", className)}>
      {isSuccess && profile ? (
        <AccountMenu profile={profile} />
      ) : isWaitlistOnly ? (
        <Button
          className="landing-header_button"
          type="primary"
          onClick={() => setIsOpen && setIsOpen(true)}
        >
          {formatMessage(intl, LANDING_HEADER_BUTTON)}
        </Button>
      ) : (
        <>
          <Link to={ROUTE_PATH.LOGIN}>
            <Button
              className="landing-header_button landing-header_button_empty"
              type="primary"
            >
              {formatMessage(intl, LANDING_HEADER_LOG_IN)}
            </Button>
          </Link>
          <Link to={ROUTE_PATH.REGISTER}>
            <Button className="landing-header_button" type="primary">
              {formatMessage(intl, LANDING_HEADER_SIGNUP)}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default injectIntl(ProfileWidget);
