import { Modal } from "antd";
import { FC } from "react";
import { injectIntl, IntlShape } from "react-intl";
import "./HeaderSideBarModal.sass";
import SideBar from "../SideBar/SideBar";

type TProps = {
  isVisible: boolean;
  setisVisible: (value: boolean) => void;
  intl: IntlShape;
};

const HeaderSideBarModal: FC<TProps> = ({ intl, isVisible, setisVisible }) => {
  return (
    <Modal
      transitionName={""}
      width="100%"
      style={{ top: 0, bottom: 0, maxWidth: "100%", margin: 0 }}
      open={isVisible}
      closeIcon={
        <button className="header-sidebar-modal__button_close"></button>
      }
      onCancel={() => setisVisible(false)}
      className="header-sidebar-modal"
      footer={[]}
    >
      <SideBar
        className={"header-sidebar-modal__sidebar"}
        closeModal={() => setisVisible(false)}
      />
    </Modal>
  );
};

export default injectIntl(HeaderSideBarModal);
