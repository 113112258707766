import { useLocation } from "react-router-dom";
import {
  useFinalizeSetUserEmailMutation,
  useFinalizeregisterMutation,
} from "../api/reg-service.api";
import { useMemo } from "react";

export const useFinalizeByToken = () => {
  const { search } = useLocation();
  const type = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get("type") as "register" | "setEmail";
  }, [search]);

  const [finalizeRegister, registerMutationState] =
    useFinalizeregisterMutation();

  const [finalizeSetUserEmail, setUserEmailMutationState] =
    useFinalizeSetUserEmailMutation();

  if (type === "register") {
    return {
      finalize: finalizeRegister,
      type,
      ...registerMutationState,
    };
  }

  return {
    finalize: finalizeSetUserEmail,
    type,
    ...setUserEmailMutationState,
  };
};
