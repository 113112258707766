import { FC, useEffect, useMemo } from "react";
import "./verificationRegistration.page.sass";
import { Button, Typography } from "antd";
import { injectIntl, IntlShape } from "react-intl";
import formatMessage from "../../utils/formatMessage";
import { Link, useLocation } from "react-router-dom";
import success from "../../resources/images/ep_success.svg";
import failed from "../../resources/images/ep_failed.svg";
import { ROUTE_PATH, isSnapshot } from "../../constants";
import { useFinalizeByToken } from "../../hooks/useFinalizeByToken";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";

const CONFIRMATION_EMAIL_PAGE_TITLE = { id: "confirmation-email-page.title" };
const CONFIRMATION_EMAIL_PAGE_DESCRIPTION_REGISTER = {
  id: "confirmation-email-page.description-register",
};
const CONFIRMATION_EMAIL_PAGE_DESCRIPTION = {
  id: "confirmation-email-page.description",
};
const CONFIRMATION_EMAIL_PAGE_LOGIN = {
  id: "confirmation-email-page.login",
};
const CONFIRMATION_EMAIL_PAGE_GO_BACK = {
  id: "confirmation-email-page.back",
};

const CONFIRMATION_EMAIL_PAGE_TITLE_FAILED = {
  id: "confirmation-email-page.title-failed",
};
// const CONFIRMATION_EMAIL_PAGE_DESCRIPTION_FAILED_REGISTER = {
//   id: "confirmation-email-page.description-failed-register",
// };
// const CONFIRMATION_EMAIL_PAGE_DESCRIPTION_FAILED = {
//   id: "confirmation-email-page.description-failed",
// };
const CONFIRMATION_EMAIL_PAGE_RE_SEND_EMAIL_FAILED = {
  id: "confirmation-email-page.login-failed-register",
};

type TProps = {
  intl: IntlShape;
};

type ErrorWithMessage = {
  data: {
    message: string;
  };
};

const VerificationRegistrationPage: FC<TProps> = ({ intl }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const {
    finalize,
    isLoading,
    isSuccess,
    isUninitialized,
    type,
    error,
    isError,
  } = useFinalizeByToken();
  const { landingId } = useGetLandingLinkByAuthorizedUser()

  const message = useMemo(() => {
    if (error && (error as ErrorWithMessage).data) {
      return (error as ErrorWithMessage).data.message;
    } else return "";
  }, [error]);

  useEffect(() => {
    if (token && isUninitialized) {
      finalize({
        token,
      });
    }
  }, [finalize, isUninitialized, token]);

  if (isSnapshot) return <div className="verification-registration-page"></div>;

  if (isLoading) return <div className="verification-registration-page"></div>;

  if (isSuccess)
    return (
      <div className="verification-registration-page">
        <div className="verification-registration-page__card">
          <img
            width={100}
            height={100}
            style={{ margin: "0 auto 24px" }}
            src={success}
            alt=""
          />
          <Typography className="verification-registration-page__title">
            {formatMessage(intl, CONFIRMATION_EMAIL_PAGE_TITLE)}
          </Typography>
          <p className="verification-registration-page__description">
            {formatMessage(
              intl,
              type === "register"
                ? CONFIRMATION_EMAIL_PAGE_DESCRIPTION_REGISTER
                : CONFIRMATION_EMAIL_PAGE_DESCRIPTION
            )}
          </p>
          {type === "register" ? (
            <Link to={`${ROUTE_PATH.LOGIN}?landing-id=${landingId}`}>
              <Button className="verification-registration-page__button">
                {formatMessage(intl, CONFIRMATION_EMAIL_PAGE_LOGIN)}
              </Button>
            </Link>
          ) : (
            <Link to={ROUTE_PATH.ROOT}>
              <Button className="verification-registration-page__button">
                {formatMessage(intl, CONFIRMATION_EMAIL_PAGE_GO_BACK)}
              </Button>
            </Link>
          )}
        </div>
      </div>
    );

  if (isError)
    return (
      <div className="verification-registration-page">
        <div className="verification-registration-page__card">
          <img
            width={100}
            height={100}
            style={{ margin: "0 auto 24px" }}
            src={failed}
            alt=""
          />
          <Typography className="verification-registration-page__title">
            {formatMessage(intl, CONFIRMATION_EMAIL_PAGE_TITLE_FAILED)}
          </Typography>
          <p className="verification-registration-page__description">
            {/* {type === "register"
            ? formatMessage(
              intl,
              CONFIRMATION_EMAIL_PAGE_DESCRIPTION_FAILED_REGISTER
            )
            : formatMessage(intl, CONFIRMATION_EMAIL_PAGE_DESCRIPTION_FAILED)} */}
            {message}
          </p>
          {type === "register" ? (
            <Link to={`${ROUTE_PATH.REGISTER}?landing-id=${landingId}`}>
              <Button className="verification-registration-page__button">
                {formatMessage(
                  intl,
                  CONFIRMATION_EMAIL_PAGE_RE_SEND_EMAIL_FAILED
                )}
              </Button>
            </Link>
          ) : (
            <Link to={ROUTE_PATH.ROOT}>
              <Button className="verification-registration-page__button">
                {formatMessage(intl, CONFIRMATION_EMAIL_PAGE_GO_BACK)}
              </Button>
            </Link>
          )}
        </div>
      </div>
    );

  return <div className="verification-registration-page"></div>;
};
export default injectIntl(VerificationRegistrationPage);
