import { IntlShape } from 'react-intl';

export type MessageDescriptor<T = void> = T extends string ? string : {
  id: string,
  default?: string,
  params?: Record<string, string | number>,
};
 const formatMessage = (intl: IntlShape, descriptor: MessageDescriptor): string => {
  if (typeof descriptor === 'string') {
    return descriptor;
  }

  return intl.formatMessage({
    id: descriptor.id,
    defaultMessage: descriptor.default,
  }, descriptor.params);
};

export default formatMessage;