import { PropsWithChildren } from "react";
import "./AuthLayout.sass";
import witAutorizedRedirect from "../../hocs/withAutorizedRedirect";
import withUnautorizedRedirect from "../../hocs/withUnautorizedRedirect";

export const AuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="auth-layout">{children}</div>;
};

export const WAuthLayoutAR = witAutorizedRedirect(AuthLayout);
export const WAuthLayoutUAR = withUnautorizedRedirect(AuthLayout);
