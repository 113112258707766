import { FC, ReactNode } from "react";
import checkViolet from "../../resources/images/circle_check_violet.svg";
import "./TariffCard.sass";
import { PLANCARD_MONTH } from "../PlanCard/PlanCard";
import formatMessage from "../../utils/formatMessage";
import { IBaseComponentProps } from "../../types";
import { injectIntl } from "react-intl";
import popular from "../../resources/images/popular.png";

type TProps = {
  title: string;
  description: string;
  price?: string;
  renderPrice?: () => ReactNode;
  items?: string[];
  button: ReactNode;
  subTitle: string;
  additionalDescription?: string;
  isPopular?: boolean
} & IBaseComponentProps;

const TariffCard: FC<TProps> = ({
  description,
  price,
  renderPrice,
  title,
  items,
  button,
  subTitle,
  additionalDescription,
  intl,
  isPopular
}) => {
  return (
    <div className={`tariff-card ${isPopular && "tariff-card__popular"}`}>
      {isPopular && <div className="tariff-card__popular_block">
        <img width={20} height={20} src={popular} alt="" />
        <p>Popular</p>
      </div>}
      <div className="tariff-card_top_block">
        <h3 className="tariff-card_title">{title}</h3>
        <p className="tariff-card_subtitle">{subTitle}</p>
        <p className="tariff-card_description">{description}</p>
        {additionalDescription && (
          <p className="tariff-card_additional_description">
            {additionalDescription}
          </p>
        )}
        {price && (
          <>
            <span className="tariff-card_price">{price}$</span>
            <span className="tariff-card_fart">
              {" "}
              /{formatMessage(intl, PLANCARD_MONTH)}
            </span>
          </>
        )}
        {renderPrice && renderPrice()}
      </div>
      {button}
      {items && (
        <div className="tariff-card_check_block">
          {items.map((el: string) => {
            return (
              <div key={el} className="tariff-card_check_block_item">
                <img src={checkViolet} alt="" />
                <p>{el}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default injectIntl(TariffCard);
