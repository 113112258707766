import { injectIntl } from "react-intl";
import formatMessage from "../../utils/formatMessage";
import { Typography } from "antd";

import "./404.page.sass";
import { IBaseComponentProps } from "../../types";

const NOT_FOUND_TITLE = {
  id: "not-found.title",
};
const NOT_FOUND_MESSAGE = {
  id: "not-found.message",
};

interface IProps extends IBaseComponentProps {}

const NotFound: React.FC<IProps> = ({ intl }) => {
  return (
    <div className="not-found">
      <div className="not-found__inner">
        <Typography.Title level={1}>
          {formatMessage(intl, NOT_FOUND_TITLE)}
        </Typography.Title>
        <Typography>{formatMessage(intl, NOT_FOUND_MESSAGE)}</Typography>
      </div>
    </div>
  );
};

export default injectIntl(NotFound);
