import ChangeEmail from "../../components/ChangeEmail/ChangeEmail";
import "./changeEmail.page.sass";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";

type TProps = {} & IBaseComponentProps;

const ChangeEmailPage: React.FC<TProps> = ({ intl }) => {
  return (
    <div className="changeEmail-page">
      <div className="changeEmail-page__card">
        <ChangeEmail />
      </div>
    </div>
  );
};

export default injectIntl(ChangeEmailPage);
