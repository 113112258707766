import { FC, useContext, useState } from "react";
import { injectIntl } from "react-intl";
import "./LandingHeader.sass";
import formatMessage from "../../utils/formatMessage";
import LogoBlock from "../LogoBlock/LogoBlock";
import { Link, useLocation } from "react-router-dom";
import { RegisterModalContext } from "../LandingLayout/LandingLayout";
import { IBaseComponentProps } from "../../types";
import useScreenWidth from "../../hooks/useScreenWidth";
import LandingHeaderModal from "../LandingHeaderModal/LandingHeaderModal";
import LandingHeaderSideBarModal from "../LandingHeaderSideBarModal/LandingHeaderSideBarModal";
import ProfileWidget from "../ProfileWidget";
import { useGetMeQuery, useGetProfileQuery } from "../../api/reg-service.api";
import { ROUTE_PATH } from "../../constants";

type TProps = {} & IBaseComponentProps;

export const LANDING_HEADER_DOCUMENTATION = {
  id: "landing-header.documentation",
};
export const LANDING_HEADER_OUR_SOLUTION = {
  id: "landing-header.our-solution",
};
// const LANDING_HEADER_PRICE = { id: "landing-header.price" }
// const LANDING_HEADER_LOG_IN = { id: "landing-header.log-in" };
// const LANDING_HEADER_BUTTON = { id: "landing-header.button" };
// const LANDING_HEADER_SIGNUP = { id: "landing-header.sign-up" };
export const LANDING_HEADER_PRICING = { id: "landing-header.pricing" };

const LandingHeader: FC<TProps> = ({ intl }) => {
  const { setIsOpen } = useContext(RegisterModalContext);

  useGetMeQuery((() => {})(), {
    pollingInterval: 60000,
    skipPollingIfUnfocused: true,
  });
  useGetProfileQuery();

  const isSmallScreen = useScreenWidth();
  const [isVisible, setIsVisible] = useState(false);
  const [isSideBaVisible, setIsSideBarVisible] = useState(false);
  const { pathname } = useLocation();
  const isDocumentationPathname = pathname.includes("documentation");

  const showModal = () => {
    setIsVisible(true);
  };
  const showSideBarModal = () => {
    setIsSideBarVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
  };
  const hideSideBarModal = () => {
    setIsSideBarVisible(false);
  };

  const headerModalClick = () => {
    if (isDocumentationPathname) {
      showSideBarModal();
    } else {
      showModal();
    }
  };

  if (isSmallScreen) {
    return (
      <div
        className={`landing-header ${
          isDocumentationPathname && "landing-header-revers"
        }`}
      >
        <div className="landing-header_logoBlock">
          <LogoBlock className="landing-header_logo" />
        </div>
        <div className="landing-header_block_right_mobile">
          <LandingHeaderModal
            buttonClick={() => setIsOpen(true)}
            isVisible={isVisible}
            setisVisible={hideModal}
          />
          <LandingHeaderSideBarModal
            isVisible={isSideBaVisible}
            setisVisible={hideSideBarModal}
          />
          <button onClick={headerModalClick}></button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="landing-header">
        <div className="landing-header_logoBlock">
          <LogoBlock className="landing-header_logo" />
        </div>
        <div className="landing-header_block_middle">
          <span>
            <Link to={ROUTE_PATH.LANDING_OUR_SOLUTION}>
              {formatMessage(intl, LANDING_HEADER_OUR_SOLUTION)}
            </Link>
          </span>
          <span>
            <Link to={ROUTE_PATH.LANDING_PRICING}>
              {formatMessage(intl, LANDING_HEADER_PRICING)}
            </Link>
          </span>
          <span>
            <Link to={ROUTE_PATH.LANDING_SERVICE_INTRODUCTION}>
              {formatMessage(intl, LANDING_HEADER_DOCUMENTATION)}
            </Link>
          </span>
        </div>
        <ProfileWidget
          className="landing-header_block_right"
          setIsOpen={setIsOpen}
        />
      </div>
    );
  }
};

export default injectIntl(LandingHeader);
