import { Button, Form, Input, Space, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import formatMessage from "../../utils/formatMessage";
// import useToken from "antd/es/theme/useToken";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import Icon from "@ant-design/icons";
import cn from "classnames";
import closeIcon from "../../resources/images/close.svg";
import "./ChangeEmail.sass";
import { Link } from "react-router-dom";
import {
  useGetProfileQuery,
  useSetEmailMutation,
} from "../../api/reg-service.api";
import { responsePathOr } from "../../utils/utils";
import { ROUTE_PATH } from "../../constants";
import { IBaseComponentProps } from "../../types";
import { useTimer } from "../../hooks/useTimer";

type TProps = {} & IBaseComponentProps;
interface IFormInput {
  email: string;
  password: string;
}

const CHANGE_EMAIL_TITLE = { id: "change-email.title" };
const CHANGE_EMAIL_LABEL_EMAIL = { id: "change-email.label-email" };
const CHANGE_EMAIL_LABEL_OLD_EMAIL = { id: "change-email.label-old-email" };
const CHANGE_EMAIL_LABEL_PASSWORD = { id: "change-email.label-password" };
const CHANGE_EMAIL_BUTTON = { id: "change-email.button" };
const CHANGE_EMAIL_VERIFY_YOUR_EMAIL = { id: "change-email.verify-your-email" };
const CHANGE_EMAIL_VERIFY_YOUR_EMAIL_DESCRIPTION = {
  id: "change-email.verify-your-email-description",
};
const CHANGE_EMAIL_BUTTON_RE_SEND = { id: "change-email.button-re-send" };

const ChangeEmail: FC<TProps> = ({ intl, className }) => {
  const { data: profile } = useGetProfileQuery();
  const { count, formattedTime, reset, start } = useTimer(80);
  const [setEmail, { isLoading }] = useSetEmailMutation();
  const landingId = profile?.landingId || ""
  const [isChangeEmailConfirm, setIsChangeEmailConfirm] =
    useState<boolean>(false);

  const [submitError, setSubmitError] = useState<string>();
  const onSubmit = async (values: IFormInput) => {
    try {
      await setEmail({
        ...values,
        newEmail: values.email.toLowerCase(),
        landingId: +landingId
      }).unwrap();
      setIsChangeEmailConfirm(true);
      reset();
      start();
    } catch (err) {
      setSubmitError(responsePathOr(err));
    }
  };
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    profile && form.setFieldValue("oldEmail", profile?.email);
  }, [form, profile]);

  const disabled = formValid || !isFocused || isLoading;
  const resendButtonDisabled = !!count || isLoading;
  const openCloseIcons = (visible: boolean) =>
    visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

  return (
    <Form
      form={form}
      scrollToFirstError
      className={cn("change-email", className)}
      onFinish={onSubmit}
      onFieldsChange={() => {
        if (submitError) setSubmitError("");
      }}
      layout="vertical"
      autoComplete="off"
      onValuesChange={() => {
        setIsFocused(true);
        form
          .validateFields()
          .then((values) => {
            const valuesAvailable = Object.values(values).every(
              (value) => value
            );
            setFormValid(!valuesAvailable);
          })
          .catch((errorInfo) => {
            const valuesAvailable = Object.values(errorInfo.values).every(
              (value) => value
            );
            if (valuesAvailable && !errorInfo.errorFields.length) {
              setFormValid(false);
            } else {
              setFormValid(true);
            }
          });
      }}
    >
      <Form.Item>
        <Typography.Title className="change-email__title" level={5}>
          {isChangeEmailConfirm
            ? formatMessage(intl, CHANGE_EMAIL_VERIFY_YOUR_EMAIL)
            : formatMessage(intl, CHANGE_EMAIL_TITLE)}
          <Link to={ROUTE_PATH.PROFILE}>
            <img src={closeIcon} alt="" />
          </Link>
        </Typography.Title>
      </Form.Item>
      {isChangeEmailConfirm && (
        <p className="change-email__description">
          {formatMessage(intl, CHANGE_EMAIL_VERIFY_YOUR_EMAIL_DESCRIPTION)}
        </p>
      )}
      <Form.Item
        label={formatMessage(intl, CHANGE_EMAIL_LABEL_OLD_EMAIL)}
        name={"oldEmail"}
        rules={[
          {
            type: "email",
          },
        ]}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        label={formatMessage(intl, CHANGE_EMAIL_LABEL_EMAIL)}
        name={"email"}
        rules={[
          {
            type: "email",
          },
        ]}
      >
        <Input readOnly={isChangeEmailConfirm} />
      </Form.Item>
      <Form.Item
        label={formatMessage(intl, CHANGE_EMAIL_LABEL_PASSWORD)}
        rules={[
          {
            min: 8,
          },
        ]}
        name={"password"}
      >
        <Input.Password
          autoComplete="new-password"
          iconRender={openCloseIcons}
          readOnly={isChangeEmailConfirm}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className="change-email__button"
            type="primary"
            disabled={isChangeEmailConfirm ? resendButtonDisabled : disabled} //need a disabled for the time of re send
            htmlType="submit"
          >
            {isChangeEmailConfirm ? (
              <>
                {formatMessage(intl, CHANGE_EMAIL_BUTTON_RE_SEND)}{" "}
                {formattedTime}
              </>
            ) : (
              formatMessage(intl, CHANGE_EMAIL_BUTTON)
            )}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default injectIntl(ChangeEmail);
