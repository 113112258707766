import { FC, PropsWithChildren } from "react";

const ScrollToTopByClick: FC<PropsWithChildren> = ({ children }) => {
  const scroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
  return (
    <div onClick={scroll}>{children}</div>
  )
}

export default ScrollToTopByClick;