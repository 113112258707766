import { Environments } from "@paddle/paddle-js";

export const ROUTE_PATH = {
  ROOT: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  VERIFY: "/verify",
  CONFIRMATION_EMAIL: "confirm-email",
  // HOME: "/dashboard",
  RPC_PANEL: "/rpc-panel",
  PROFILE: "/profile",
  CHANGE_EMAIL: "/change-email",
  PAYMENT: "/payment",
  LANDING_SERVICE_INTRODUCTION: "/documentation/",
  LANDING: "/",
  LANDING_OUR_SOLUTION: "/our-solution",
  LANDING_DOCUMENTATION: "/documentation",
  // LANDING_SERVICE_INTRODUCTION: "/documentation/service-introduction",
  LANDING_PRICING: "/documentation/pricing",
  LANDING_API_DOCUMENTATION: "/documentation/api",
  LANDING_SHORT_OVERVIEW: "/documentation/short-overview",
  LANDING_TERMS_OF_SERVICE: "/documentation/terms-of-service",
  LANDING_PRIVACY_POLICY: "/documentation/privacy-policy",
};

export const PLAN_TS_STORAGE_KEY = "planPending";
export const SUBSCRIPTION_TS_STORAGE_KEY = "subscriptionPending";

export const EXTERNAL_LINKS = {
  TERPMS_OF_SERVICE:
    (window as Window & typeof globalThis & { env: Record<string, string> }).env
      ?.TERMS_OF_SERVICE_LINK || "",
  PRIVACY_POLICY:
    (window as Window & typeof globalThis & { env: Record<string, string> }).env
      ?.PRIVACY_POLICY_LINK || "",
  BESTARCH: "https://bestarch.ae/",
  TELEGRAM: "https://t.me/instantnodes",
  LINKED: "https://www.linkedin.com/company/instantnodes/",
  TWITTER: "https://x.com/InstantNodes",
};

const RPC_ROOT_HTTP =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.RPC_ROOT_HTTP || "solana-api.instantnodes.io/v2/token-";

const RPC_ROOT_WS =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.RPC_ROOT_WS || "solana-api.instantnodes.io/v2/token-";

export const DOCUMENTATION_PATH =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.DOCUMENTATION_PATH || "/documentation/service-introduction";

export const DEFAULT_LANDING_ID = "1";

export const createRpcUrl = (apiKey: string, wss?: boolean) => {
  return `${wss ? "wss" : "https"}://${
    wss ? RPC_ROOT_WS : RPC_ROOT_HTTP
  }${apiKey}`;
};

export const isWaitlistOnly =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.WAITLIST_ONLY === "true";

const contactEmail =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.CONTACT_EMAIL || "";

export const CONTACT_EMAIl = contactEmail || "support@instantnodes.io";

export const gaId =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.GOOGLE_METRICS_ACCOUNT_ID || "";

export const scProject =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.SC_PROJECT || "";

export const scSecurity =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.SC_SECURITY || "";

export const stripePK =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.STRIPE_PUBLISHABLE_KEY || "";

export const paddleCST =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.PADDLE_CLIENT_SIDE_TOKEN || "";

export const paddleEnv: Environments =
  ((window as Window & typeof globalThis & { env: Record<string, string> }).env
    ?.PADDLE_ENVIROMENT as Environments) || "sandbox";

export const isSnapshot = navigator.userAgent === "ReactSnap";

export const DOCUMENTATION_API_UPDATE_TIME = 1720170890000;
export const DOCUMENTATION_PRICING_UPDATE_TIME = 1721232010000;
export const DOCUMENTATION_SERVICE_INTRODUCTION_UPDATE_TIME = 1720170890000;

function getLandingLinks() {
  const landingLinks: Record<string, string> = {};
  const env = (
    window as Window & typeof globalThis & { env: Record<string, string> }
  ).env;
  if (env) {
    for (const key in env) {
      if (key.startsWith("LANDING_LINK_")) {
        landingLinks[key] = env[key];
      }
    }
  }
  return landingLinks;
}
export const LANDING_LINKS = getLandingLinks();
