import BigNumber from "bignumber.js";

export function convertBigNumberToNumber(price?: number, decimals?: number) {
  if (!price || !decimals) return "0";

  return convertBigNumber(price, decimals).toString();
}

export function convertBigNumber(price?: number, decimals?: number) {
  if (!price || !decimals) return new BigNumber(0);

  return new BigNumber(price || 0).div(new BigNumber(10).pow(decimals));
}
