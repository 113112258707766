import RegisterForm from "../../components/RegisterForm";
import "./register.page.sass";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";

interface IProps extends IBaseComponentProps { }




const RegisterPage: React.FC<IProps> = ({ intl }) => {

  return (
    <div className="register-page">
      <div className="register-page__card">
        <RegisterForm
          iswithVerificationStage
        // onSuccessCb={(email: string) => {
        //   navigate(`${ROUTE_PATH.VERIFY}/?email=${email}`);
        // }}
        />
      </div>
    </div>
  );
};

export default injectIntl(RegisterPage);
