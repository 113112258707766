import React, { FC, useEffect, useMemo, useState } from "react";
import "./Home.sass";
import useToken from "antd/es/theme/useToken";
import { Typography } from "antd";
import formatMessage from "../../utils/formatMessage";
import { FormattedMessage, injectIntl } from "react-intl";

import {
  regServiceApi,
  useCancelSubscriptionMutation,
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
  useGetPaymentTokensQuery,
} from "../../api/reg-service.api";
import useAvailablePlans from "../../hooks/useAvailablePlans";
import { IBaseComponentProps } from "../../types";
import { LANDING_PACKAGES_BLOCK_CONTACT_US_BUTTON } from "../LandingPackagesBlock/LandingPackagesBlock";
import { convertBigNumberToNumber } from "../../utils/convertBigNumberToNumber";
import { TPlan } from "../../entities/plan";
import usePlanType, { EPlanType } from "../../hooks/usePlanType";
import CheckoutModal from "../CheckoutModal";
import DashboardInformationWindow from "../DashboardInformationWindow";
import useQueryWithPolling from "../../hooks/useQueryWithPolling";
import SubscriptionSettingsModal from "../SubscriptionSettingsModal";
import SubscriptionDetails from "../SubscriptionDetails";
import useSubscriptionDetails from "../../hooks/useSubscriptionDetails";
import { TSubscription } from "../../entities/subscription";
import {
  PLAN_TS_STORAGE_KEY,
  SUBSCRIPTION_TS_STORAGE_KEY,
} from "../../constants";
import { pick } from "ramda";
import useSubscriptionEdit from "../../hooks/useSubscriptionEdit";
import { useDispatch } from "react-redux";
import { ButtonGroupsSmoothOption } from "../ButtonGroupsSmooth/ButtonGroupsSmooth";
import NewPlanCard from "../NewPlanCard";

type TProps = {} & IBaseComponentProps;
type TariffKeys = "HybridN125" | "HybridN225" | "HybridN425" | "HybridN1500";
const HOME_PAGE_DASHBOARD = { id: "home-page.dashboard" };
const HOME_PAGE_MY_PLAN = { id: "home-page.my-plan" };
const HOME_PAGE_MY_PLAN_DESCRIPTION = { id: "home-page.my-plan-description" };
// const HOME_PAGE_MY_PLANITEM_DESCRIPTION = {
//   id: "home-page.my-plan-item-description",
// };
export const HOME_PAGE_MY_PLANITEM_SUBTITLE = {
  id: "home-page.my-plan-item-subtitle",
};
export const HOME_PAGE_MY_PLANITEM_DESCRIPTION_PRO = {
  id: "home-page.my-plan-item-description-pro",
};
export const HOME_PAGE_MY_PLANITEM_SUBTITLE_PRO = {
  id: "home-page.my-plan-item-subtitle-pro",
};
export const HOME_PAGE_MY_PLANITEM_DESCRIPTION_HISTORICAL = {
  id: "home-page.my-plan-item-description-historical",
};
export const HOME_PAGE_MY_PLANITEM_SUBTITLE_HISTORICAL = {
  id: "home-page.my-plan-item-subtitle-historical",
};
// const LANDING_PACKAGES_BLOCK_CUSTOM_ITEM_RPC = {
//   id: "landing-packages-block.custom-item-rpc",
// };
// const LANDING_PACKAGES_BLOCK_CUSTOM_LIMITS_RPC = {
//   id: "landing-packages-block.custom-limits",
// };
export const HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION = {
  id: "home-page.my-plan-item-additional-description",
};
export const HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_PRO = {
  id: "home-page.my-plan-item-additional-description-pro",
};
export const HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_TAILORED = {
  id: "home-page.my-plan-item-additional-description-tailored",
};
const HOME_PAGE_TAILORED = { id: "home-page.tailored" };
const HOME_PAGE_FREE = { id: "home-page.free" };
// const HOME_PAGE_SUPPORT = { id: "home-page.support" };
// const HOME_PAGE_PRO = { id: "home-page.pro" };
// const HOME_PAGE_WEBHOOCK = { id: "home-page.webhook" };
// const HOME_PAGE_BUTTON_FREE = { id: "home-page.button-free" };
// const HOME_PAGE_BUTTON_EXTEND = { id: "home-page.button-extend" };
// const HOME_PAGE_REQUESTS_PER_SECOND = {
//   id: "home-page.requests-per-second",
// };
// const HOME_PAGE_MY_PLAN_CUSTOM_RPC = {
//   id: "landing-packages-block.custom-item-rpc",
// };
// const HOME_PAGE_MY_PLAN_LIMITS = {
//   id: "landing-packages-block.custom-limits",
// };
// const HOME_PAGE_MY_PLAN_TALK = {
//   id: "plan-card.lets-talk",
// };

// const LANDING_PACKAGES_BLOCK_ARCHIVE_DATA = {
//   id: "landing-packages-block.archive-data",
// };
const LANDING_PACKAGES_TAX = {
  id: "landing-packages-block.tax",
};
const PLANCARD_MONTH = { id: "plan-card.month" };
const HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION = {
  id: "home-page.your-active-subscription",
};
// const HOME_PAGE_TRIAL_MODE = { id: "home-page.trial-mode" };
// const HOME_PAGE_YOUR_CURRENT_PLAN = { id: "home-page.your-current-plan" };

const Home: FC<TProps> = ({ intl }) => {
  const [, token] = useToken();
  const { plans, paidTariffs } = useAvailablePlans({});

  const paidTariffsOptions = useMemo(
    () =>
      paidTariffs.map((el, index) => {
        return {
          title: `${el.rps.toString()} RPS`,
          value: el.name,
        } as ButtonGroupsSmoothOption;
      }),
    [paidTariffs]
  );

  const [selectedPaidTariffOption, setSelectedPaidTariffOption] =
    useState<ButtonGroupsSmoothOption>({ title: "0", value: "0" });

  const selectedPlan = useMemo(() => {
    return (
      paidTariffs.find((el) => el.name === selectedPaidTariffOption.title) ||
      paidTariffs[0]
    );
  }, [paidTariffs, selectedPaidTariffOption.title]);

  useEffect(() => {
    setSelectedPaidTariffOption({
      title: paidTariffs[0]?.name || "0",
      value: paidTariffs[0]?.name || "0",
    });
  }, [paidTariffs]);

  const { data: tokens } = useGetPaymentTokensQuery();
  const dispatch = useDispatch();

  const comparePlan = (data?: TPlan, prevData?: TPlan) => {
    return !!(
      data?.id !== prevData?.id || data?.validUntil !== prevData?.validUntil
    );
  };

  const { startPolling: startPollingCurrentPlan } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentPlan,
    PLAN_TS_STORAGE_KEY,
    pick(["id", "validUntil"]),
    comparePlan
  );

  const compareSubscription = (
    data: TSubscription,
    prevData: TSubscription
  ) => {
    return !!(
      data?.id !== prevData?.id ||
      data?.status !== prevData?.status ||
      data?.nextBilledAt !== prevData?.nextBilledAt
    );
  };

  const { startPolling: startPollingCurrentSubscription } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentSubscription,
    SUBSCRIPTION_TS_STORAGE_KEY,
    pick(["id", "status", "nextBilledAt"]),
    compareSubscription
  );

  const currentPlanQuery = useGetCurrentPlanQuery((() => {})(), {
    pollingInterval: 60000,
  });
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery(
    (() => {})(),
    {
      pollingInterval: 60000,
    }
  );

  const { subscription, badge, standardButtonProps } = useSubscriptionDetails(
    currentPlanQuery,
    currentSubscriptionQuery,
    selectedPlan?.name || ""
  );

  const { canEdit, disabledCancel, disabledUpdate } = useSubscriptionEdit(
    currentPlanQuery,
    currentSubscriptionQuery
  );

  const paymentToken = tokens ? tokens[0] : undefined;

  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [visible, setVisible] = useState(false);

  const planType = usePlanType(currentPlanQuery.data);
  const [isShowSubscriptionSettingsModal, setIsShowSubscriptionSettingsModal] =
    useState(false);
  // const [
  //   isShowDashboardInformationWindow,
  //   setIsShowDashboardInformationWindow,
  // ] = useState(true);

  const showModal = () => {
    dispatch(regServiceApi.util.invalidateTags(["priceItem"]));
    setVisible(true);
  };

  // const hideDashboardInformationWindow = () => {
  //   setIsShowDashboar] dInformationWindow(false);
  // };

  const showSubscriptionSettingsModal = () => {
    setIsShowSubscriptionSettingsModal(true);
  };

  const defaultPlanButtonProps = {
    disabled: true,
    title:
      planType === EPlanType.DEFAULT &&
      (!subscription ||
        (subscription.status !== "canceled" &&
          subscription?.status !== "past_due"))
        ? formatMessage(intl, HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION)
        : undefined,
  };

  const isTailoredNoCanceled =
    subscription?.status !== "canceled" && planType === EPlanType.TAILORED;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const additionalDataAboutPaidTariffs: Record<
    TariffKeys,
    Record<string, string>
  > = {
    HybridN125: {
      requestsPerMonth: "11.25",
      requestsPerDay: "375 thousand",
      requestsPerSecond: "125",
      transferIncluded: "1",
      webSocketsConnections: "10",
    },
    HybridN225: {
      requestsPerMonth: "50",
      requestsPerDay: "4 million",
      requestsPerSecond: "225",
      transferIncluded: "5",
      webSocketsConnections: "15",
    },
    HybridN425: {
      requestsPerMonth: "100",
      requestsPerDay: "9 million",
      requestsPerSecond: "425",
      transferIncluded: "20",
      webSocketsConnections: "30",
    },
    HybridN1500: {
      requestsPerMonth: "500",
      requestsPerDay: "17.5 million",
      requestsPerSecond: "1500",
      transferIncluded: "40",
      webSocketsConnections: "75",
    },
  };

  const dataBySelectedTariff =
    additionalDataAboutPaidTariffs[selectedPlan?.name as TariffKeys];

  const checkItemsStandart = useMemo(() => {
    const result = [
      "Solana dedicated nodes",
      `Up to ${
        dataBySelectedTariff?.requestsPerMonth || "..."
      } million requests per month`,
      `Up to ${dataBySelectedTariff?.requestsPerDay || "..."} requests per day`,
      `Up to ${
        dataBySelectedTariff?.requestsPerSecond || "..."
      } requests per second`,
      `${
        dataBySelectedTariff?.transferIncluded || "..."
      } TB data transfer included`,
      `${
        dataBySelectedTariff?.webSocketsConnections || "..."
      } WebSocket connections`,
      "Heavy Requests (x1000 faster)",
      "Medium Request (x10 faster)",
      "Light Requests (x2.5 faster)",
      "Full Historical Archive Data",
      "Auto-failover redirects traffic during issues",

      {
        text: "Geyser Internal Boost",
        hint: "We do not sell Geyser as an extra service. You get even better boost when using our Hybrid solution for free.",
      },
    ];
    if (
      selectedPlan?.name === "HybridN225" ||
      selectedPlan?.name === "HybridN425"
    ) {
      result.push("Dedicated support on Telegram");
    }
    if (selectedPlan?.name === "HybridN1500") {
      result.push("Personal manager on Telegram");
    }

    return result;
  }, [dataBySelectedTariff, selectedPlan?.name]);

  const onButtonGroupsSmoothChange = (event: string) => {
    const _selectedTariff =
      paidTariffs.find((el) => el.name === selectedPaidTariffOption.title) ||
      paidTariffs[0];

    if (_selectedTariff && paymentToken) {
      window.gtag &&
        window.gtag("event", "select_plan", {
          plan_name: _selectedTariff?.name,
          page: window.location.pathname,
          plan_price: convertBigNumberToNumber(
            _selectedTariff?.price,
            paymentToken?.decimals
          ),
          time: new Date().toISOString(),
        }); // need to refactor all home
    }
    setSelectedPaidTariffOption({ title: event, value: event });
  };

  return (
    <div
      style={{
        color: token.colorPrimary,
      }}
      className="home"
    >
      <Typography className="home__title">
        {formatMessage(intl, HOME_PAGE_DASHBOARD)}
      </Typography>
      {/* {isShowDashboardInformationWindow && ( */}
      {badge && (
        <DashboardInformationWindow // replace false
          // closeWindow={hideDashboardInformationWindow}
          className="home__info_block"
          text={<FormattedMessage {...badge.message} />}
          type={badge.type}
        />
      )}
      {/* )} */}
      <div style={{ minHeight: "112px" }}>
        {currentPlanQuery.data && subscription && (
          <SubscriptionDetails
            editPlanAction={showSubscriptionSettingsModal}
            subscription={subscription}
            canEdit={canEdit}
            plan={currentPlanQuery.data}
          />
        )}
      </div>
      {plans?.length && (
        <div className="home__plans">
          <Typography className="home_myplan_block_title">
            {formatMessage(intl, HOME_PAGE_MY_PLAN)}
          </Typography>
          <p className="home_subtitle">
            {formatMessage(intl, HOME_PAGE_MY_PLAN_DESCRIPTION)}
          </p>
          <div className="home_myplan_block">
            <NewPlanCard
              className="home_myplan_block__item _indent"
              renderPrice={() => (
                <div
                  style={{ color: "#ffffff99" }}
                  className="myplan__price-text"
                >
                  Free
                </div>
              )}
              title={formatMessage(intl, HOME_PAGE_FREE)}
              checkItems={[
                "Solana dedicated nodes",
                "Up to 2 million requests per month",
                "Up to 50 thousand requests per day",
                "Up to 10 requests per second",
                "100 GB data transfer included",
                "1 WebSocket connections",
                "Heavy Requests (x1000 faster)",
                "Medium Request (x10 faster)",
                "Light Requests (x2.5 faster)",
                "Full Historical Archive Data",
                "Auto-failover redirects traffic during issues",
                {
                  text: "Geyser Internal Boost",
                  hint: "We do not sell Geyser as an extra service. You get even better boost when using our Hybrid solution for free.",
                },
              ]}
              buttonDisabled={defaultPlanButtonProps.disabled}
              buttonTitle={defaultPlanButtonProps.title}
            />
            <NewPlanCard
              isBeastMode={
                selectedPlan.name === "HybridN1500" ||
                selectedPlan.name === "HybridN425"
              }
              selectedPaidTariffOption={selectedPaidTariffOption}
              paidTariffsOptions={paidTariffsOptions}
              onButtonGroupsSmoothChange={onButtonGroupsSmoothChange}
              title={selectedPlan?.name || paidTariffs[0].name || ""}
              className="home_myplan_block__item"
              buttonClick={() => {
                window.gtag &&
                  window.gtag("event", "click_subscribe", {
                    plan_name: selectedPlan?.name,
                    page: window.location.pathname,
                    plan_price: convertBigNumberToNumber(
                      selectedPlan?.price,
                      paymentToken?.decimals
                    ),
                    time: new Date().toISOString(),
                  });
                showModal();
              }}
              renderPrice={() => (
                <>
                  <div className="packages-block__price">
                    <span className="tariff-card_price">
                      {convertBigNumberToNumber(
                        selectedPlan?.price,
                        paymentToken?.decimals
                      )}
                      $
                    </span>
                    <span className="tariff-card_fart">
                      {" "}
                      /{formatMessage(intl, PLANCARD_MONTH)}
                    </span>
                  </div>
                  <div className="packages-block__price-hint">
                    {formatMessage(intl, LANDING_PACKAGES_TAX)}
                  </div>
                </>
              )}
              checkItems={checkItemsStandart}
              buttonTitle={
                standardButtonProps
                  ? formatMessage(intl, standardButtonProps.buttonTitle)
                  : undefined
              }
              buttonDisabled={standardButtonProps?.disabled || false}
              buttonType={"primary"}
            />
            <NewPlanCard
              className="home_myplan_block__item _indent"
              title={formatMessage(intl, HOME_PAGE_TAILORED)}
              buttonTitle={
                isTailoredNoCanceled
                  ? formatMessage(intl, HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION)
                  : formatMessage(
                      intl,
                      LANDING_PACKAGES_BLOCK_CONTACT_US_BUTTON
                    )
              }
              checkItems={[
                "Solana dedicated nodes",
                "Up to unlimited requests per month",
                "Up to unlimited requests per day",
                "Up to unlimited per second",
                "unlimited data transfer included",
                "unlimited WebSocket connections",
                "Heavy Requests (x1000 faster)",
                "Medium Request (x10 faster)",
                "Light Requests (x2.5 faster)",
                "Full Historical Archive Data",
                "Auto-failover redirects traffic during issues",
                {
                  text: "Geyser Internal Boost",
                  hint: "We do not sell Geyser as an extra service. You get even better boost when using our Hybrid solution for free.",
                },
                "Solana Geyser Plugin upon request",
              ]}
              renderPrice={() => {
                if (!isTailoredNoCanceled) {
                  return (
                    <div
                      style={{ color: "#7A8CF9" }}
                      className="myplan__price-text"
                    >
                      {/* {formatMessage(intl, HOME_PAGE_MY_PLAN_TALK)} */}
                      Custom
                    </div>
                  );
                }
              }}
              isButtonLinkToEmail
              buttonType="primary"
              buttonDisabled={isTailoredNoCanceled}
              isActiveTailoredPlan={isTailoredNoCanceled}
            />
          </div>
          <CheckoutModal
            selectedPlan={selectedPlan}
            visible={visible}
            onClose={() => {
              setVisible(false);
            }}
            onComplete={() => {
              startPollingCurrentPlan("creation");
              startPollingCurrentSubscription("creation");
            }}
          />
          <SubscriptionSettingsModal
            visible={isShowSubscriptionSettingsModal}
            disableUpdate={disabledUpdate}
            disableCancel={disabledCancel}
            onClose={() => {
              setIsShowSubscriptionSettingsModal(false);
            }}
            onCancelSubscription={async () => {
              cancelSubscription().then(() =>
                startPollingCurrentSubscription("cancellation")
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
export default injectIntl(Home);
