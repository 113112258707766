import { useMemo } from "react";
import { TPlan } from "../entities/plan";

export enum EPlanType {
  TAILORED,
  DEFAULT,
  STANDARD,
  NEW_STANDARD,
  UNKNOWN,
}

const usePlanType = (plan?: TPlan) => {
  const planType = useMemo(() => {
    if (!plan) return EPlanType.UNKNOWN;
    if (plan.isTailored) return EPlanType.TAILORED;
    if (plan.isDefault) return EPlanType.DEFAULT;
    if (!plan.isAvailable && !plan.isDefault && !plan.isTailored)
      return EPlanType.STANDARD; // Why?
    if (plan.isAvailable && !plan.isDefault && !plan.isTailored)
      return EPlanType.NEW_STANDARD;
    // if (plan.isAvailable && !plan.isDefault) return EPlanType.STANDARD;
    return EPlanType.TAILORED;
  }, [plan]);
  return planType;
};
export default usePlanType;
