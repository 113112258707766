import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type TPending = { ts: number; trigger: string } & Record<string, any>;

const initialState: {
  planPending?: TPending;
  subscriptionPending?: TPending;
} = {
  planPending: undefined,
  subscriptionPending: undefined,
};

const pendingsSlice = createSlice({
  name: "pendings",
  initialState,
  reducers: {
    createPending(
      state,
      {
        payload,
      }: PayloadAction<{ type: keyof typeof initialState; pending: TPending }>
    ) {
      state[payload.type] = payload.pending;
    },
    deletePending(
      state,
      { payload }: PayloadAction<keyof typeof initialState>
    ) {
      state[payload] = undefined;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = pendingsSlice;

export const selectPending =
  (name: keyof typeof initialState) => (state: RootState) =>
    state.reducer.pendings[name];

export const { createPending, deletePending } = actions;
// Export the reducer, either as a default or named export
export default reducer;
