import { Helmet } from "react-helmet-async";
import { scProject, scSecurity } from "../../constants";
import { useEffect } from "react";

function initGlobalScVars() {
  window["sc_project"] = scProject;
  window["sc_invisible"] = 1;
  window["sc_security"] = scSecurity;
}

const StatCounter = () => {
  useEffect(() => {
    if (scProject && scSecurity) {
      initGlobalScVars();
    }
  }, []);
  if (!scProject || !scSecurity) return null;
  return (
    <Helmet>
      <script
        type="text/javascript"
        src="https://www.statcounter.com/counter/counter.js"
        async
      />
    </Helmet>
  );
};
export default StatCounter;
